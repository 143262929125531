import React from 'react';

import styles from './Modals.module.scss'


const MidiModal = ({ children, leftColumn, className, onClose }) => {

  const handleOnClose = (ee) => {
    //Check that not children element was clicked
    if (ee.target === ee.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={`Register ${className?className:''}`} onClick={handleOnClose}>
      <div className="box shadow">
        <div className="row g-0">
          {leftColumn &&
          <div className="col-lg-5">
            {leftColumn}
          </div>}
          <div className={`col-lg-${leftColumn?7:12}`}>
            <div className={styles.closeContainer}><button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button></div>
            <div className="item-right">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MidiModal