import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { mediaURL } from '../../utils';


function ArtistItem({isFirst, artist, isSingle}) {

	const imgVariation = isSingle?'view':'thumb';
	const imgSize = isSingle?128:64;

  const genres = artist.genres ? artist.genres.split(',') : null;

  const artistUrl = `/${artist.slug}`;

  return (
    <div className={`${isFirst?'item':'item mt-3'}`}>
      <div className="row g-0">
        
        <div className="col-sm-12">
          <div className="media">
              <Link to={artistUrl} className="d-block" style={{marginRight: 20}}>
                {artist.artwork
                  ? <img style={{maxWidth: 103, borderRadius: 10}} src={mediaURL(artist.artwork['view'].jpeg)} className="img-fluid align-self-center" alt="..."/>
                  : <div style={{display: 'inline-block', width: 103, height: 103}}></div>
                }
              </Link>
              <div className="media-body">
                <h5 className="mt-0">&nbsp;</h5>
                <h4><Link to={artistUrl}>{artist.name}</Link></h4>
                {genres &&
                  <div id="song_data">
                    {genres.map(genre => (
                      <Link to={artistUrl} key={genre} className="midi-badge">{genre}</Link>
                    ))}
                  </div>
                }
              </div>
          </div>
        </div>

        
      </div>
    </div>
  )
}

export default ArtistItem