import React, { useState } from 'react';
import {  Button, Form, NavLink, Spinner } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux'
import { Registration } from '../../store/action/AuthAction'
import { GET_USER_INFO_SUCCESS } from '../../store/action/ActionType'

import MidiModal from './MidiModal';
import { Link, useHistory } from 'react-router-dom';
import ModalMessage from './ModalMessage';
import SocialLogin from '../socialLogin/SocialLogin';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { getAccessToken } from '../../utils';
import styles from './Modals.module.scss'

const UserInterests = ({ setActiveModal }) => {

  const dispatch = useDispatch()
  const history = useHistory();

  const { get_user_info_success } = useSelector(state => state.user);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAccessToken(),
    },
  };

  const saveInterests = async (checkedTypes, selectedValue, config) => {
    const resp = await axios.post(process.env.REACT_APP_BACKEND_URL+"/user/interests/", { 
      types: checkedTypes, 
      artists: selectedValue,
    }, config);
    dispatch({ type: GET_USER_INFO_SUCCESS, data: { ...get_user_info_success, has_interests: true } });
  }

  const handleOnCancel = async () => {
    await saveInterests([], [], config);
  }

  const kinds = [['musician', 'Musician'], ['dj', 'DJ'], ['producer', 'Producer'], ['aspiring', 'Aspiring']];

  const [selectedValue, setSelectedValue] = useState([]);
  const [checkedTypes, setCheckedTypes] = useState({});
  const [isValidated, setIsValidated] = useState(false);

  const isTypesValid = () => Object.keys(checkedTypes).length>0;
  const isArtistsValid = () => selectedValue.length>0;

  const handleSubmint = async (e) => {
    e.preventDefault();
    if (isValidated || isTypesValid() && isArtistsValid()) {
      await saveInterests(Object.keys(checkedTypes), selectedValue, config);
    } else {
      setIsValidated(true);
    }
  }

  const loadOptions = (inputValue) => {
    const form = new FormData()
    form.append('search', inputValue)
    return axios.post(process.env.REACT_APP_BACKEND_URL+"/music/quick-artist-search/", form, config).then(res => res.data);
  };
 
  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
  }

  const handleTypesChange = (evt, rk) => {
    const newVal = {...checkedTypes};
    if (evt.target.checked) {
      newVal[rk] = true;
    } else {
      delete newVal[rk];
    }
    setCheckedTypes(newVal)
  }

  return (
    <>
    <MidiModal onClose={handleOnCancel} className={styles.userInterests}>
      <h3>A bit more about you</h3>
      <Form className="login" style={{marginTop: 25}} onSubmit={handleSubmint}>
        <Form.Group className="mb-3">
          <Form.Label>What type of music creative are you?</Form.Label>
          {kinds.map(([rk, rt]) => (
            <Form.Check
              inline
              label={rt}
              key={rk}
              name="kind"
              type="checkbox"
              id={`kind-${rk}`}
              style={{minWidth: 100, textAlign: 'left'}}
              checked={checkedTypes[rk]}
              onChange={e=>handleTypesChange(e, rk)}
            />
          ))}
          {isValidated && !isTypesValid() && <div className="validation-hint">Please choose some types (optional)</div>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Please enter a few of your favorite artists</Form.Label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isMulti
            menuPlacement="top"
            value={selectedValue}
            getOptionLabel={e => <div className={styles.artistItem}>{!!e.image && <img src={e.image} width={48} height={48}/>} {e.name}</div>}
            getOptionValue={e => e.id}
            loadOptions={loadOptions}
            onChange={handleChange}
            placeholder="Start typing the artist..."
          />
          {isValidated && !isArtistsValid() && <div className="validation-hint">Please enter some artists (optional)</div>}
        </Form.Group>

        <Button type="submit">Save</Button>
        {isValidated && <Button onClick={handleOnCancel} style={{marginLeft: 15}} variant="secondary">Cancel</Button>}
      </Form>
    </MidiModal>
    </>
  );
};

export default UserInterests