import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {changePassord} from '../../store/action/AuthAction'
import {getUserInfo, updateUserInfo} from '../../store/action/UserAction'
import {UPDATE_USER_INFO_SUCCESS_STATUS} from '../../store/action/ActionType'
import { Col, Form, Row } from 'react-bootstrap'

import styles from './Profile.module.scss'
import UserSnackBar from '../../component/snackbar/UserSnackBar'
import { getAccessToken } from '../../utils'

function Profile() {

    //const [profilePicture, setProfilePicture] = useState(null);
    //const [profileImgData, setProfileImgData] = useState(null);

		const [view, setView] = useState('view_profile')

		const views = {
			'view_profile': 'Profile Data',
			'edit_profile': 'Edit Profile',
			'edit_password': 'Change Password',
		}

    const dispatch = useDispatch()
    const {get_user_info_success,
        update_user_info_success, update_user_info_fail } = useSelector(state => state.user)
    
    const {accessToken, changePasswordSuccess, changePasswordFail} = useSelector(state => state.auth)
    const userInfo = get_user_info_success
    

    const [UpdateUserInfos, setUpdateUserInfos] = useState({
        first_name:userInfo ? userInfo.first_name :'',
        last_name:userInfo ? userInfo.last_name :'',
    })

    const [changePassword, setChangePassword] = useState({
        current_password:'',
        new_password:'',
        re_new_password:''
    })
    
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
    };

    useEffect(() => {
        {!get_user_info_success && dispatch(getUserInfo(config))}
    }, [])

		useEffect(()=>{
			setUpdateUserInfos({
					first_name:userInfo ? userInfo.first_name :'',
					last_name:userInfo ? userInfo.last_name :'',
			});
    },[get_user_info_success])

    useEffect(()=>{
			if (update_user_info_success === true){
				//setProfilePicture(null)
				//setProfileImgData(null)
				setView('view_profile')
			} 
    },[update_user_info_success])

		useEffect(()=>{
			if (changePasswordSuccess === true){
				setView('view_profile')
			} 
		},[changePasswordSuccess])

      // const onChangeProfilePicture = e => {
       
      //   if (e.target.files[0]) {
      //       setProfilePicture(e.target.files[0]);
      //     const reader = new FileReader();
      //     reader.addEventListener("load", () => {
      //       setProfileImgData(reader.result);
      //     });
      //     reader.readAsDataURL(e.target.files[0]);
      //   }
      // };
     
      // const CancleProfilePicUpdate = () =>{
      //   setProfileImgData(null)
      //   setProfilePicture(null)
      // }

      // const HandleProfilePictureChange = (e) =>{
      //   e.preventDefault()
      //   let formData = new FormData()
      //   {profilePicture!==null && formData.append('profilePic', profilePicture )}
      //   dispatch(updateUserInfo(userInfo.id, formData, config))
      // }

      const handleUpdateUserInfoInput = (e) =>{
        setUpdateUserInfos(prevState=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))
          
      }


      const handleChangePasswrodInput = (e) =>{

        setChangePassword(prevState=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))
          
      }


    //   Hande show changes

      const handleShowProfile = () =>{
        dispatch({type:UPDATE_USER_INFO_SUCCESS_STATUS})
        setChangePassword({
            current_password:'',
            new_password:'',
            re_new_password:''
        })

        setUpdateUserInfos({
            first_name:userInfo ? userInfo.first_name :'',
            last_name:userInfo ? userInfo.last_name :'',
        })
        setView('view_profile')
      }

      const handleShowEditProfile = () =>{
        setChangePassword({
            current_password:'',
            new_password:'',
            re_new_password:''
        })
        setView('edit_profile')
      }

      const handleShowChangePassword = () =>{
        setUpdateUserInfos({
            first_name:userInfo ? userInfo.first_name :'',
            last_name:userInfo ? userInfo.last_name :'',
        })
        setView('edit_password')
      }


    const handleUserInfoSubmit = () =>{
        const formData = new FormData()
        formData.append('first_name', UpdateUserInfos.first_name)
        formData.append('last_name', UpdateUserInfos.last_name)
        dispatch(updateUserInfo(userInfo && userInfo.id, formData, config))
    }

    const handleChangePassword = () =>{
        const formData = new FormData()
        formData.append('current_password', changePassword.current_password)
        formData.append('new_password', changePassword.new_password)
        formData.append('re_new_password', changePassword.re_new_password)
        dispatch(changePassord(formData, config))
    }
   
    return (
			<div className="Profile">
        <h2>Profile</h2>
        {userInfo && (userInfo.first_name || userInfo.last_name) && 
        <div className="item">
          <h4>Name</h4>
          <h3>{userInfo && userInfo.first_name} {userInfo && userInfo.last_name}</h3>
        </div>}
        <div className="item">
          <h4>Email</h4>
          <p>{userInfo && userInfo.email}</p>
        </div>
        <div className="item-link">
          <a href="#0" onClick={ee=>ee.preventDefault()||handleShowEditProfile()}>Edit profile</a>
          <a href="#0" onClick={ee=>ee.preventDefault()||handleShowChangePassword()}>Change Password</a>
        </div>
        <div className={`item ${styles.profileForm}`}>
          {view==='edit_profile' && 
            <>
              <Row>
                  <Col md={6}>
                    <Form.Label>First Name</Form.Label>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Control value={UpdateUserInfos.first_name} onChange={e=> {handleUpdateUserInfoInput(e)}} name="first_name" type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="First Name" />
                      <span className={styles.validationError}>{update_user_info_fail && update_user_info_fail.data.first_name}</span>
                    </Form.Group>
                  </Col>
              </Row>
              
              <Row>
                  <Col md={6}>
                    <label>Last Name</label>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Control value={UpdateUserInfos.last_name} onChange={e=> {handleUpdateUserInfoInput(e)}}  name="last_name" type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="Last Name"/>
                        <span className={styles.validationError}>{update_user_info_fail && update_user_info_fail.data.last_name}</span>
                    </Form.Group>
                  </Col>
              </Row>
            </>
          }
                    
          {view==='edit_password' && 
            <>
              <Row>
                <Col md={6}>
                  <Form.Label>Current Password</Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control name="current_password" onChange={e=> handleChangePasswrodInput(e)} type="password" className="form-control form-control-sm" id="colFormLabelSm" placeholder="current password"/>
                    <span className={styles.validationError}>{changePasswordFail && changePasswordFail.data.current_password}</span>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                    <Form.Label>New Password</Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control name="new_password" onChange={e=> handleChangePasswrodInput(e)} type="password" className="form-control form-control-sm" id="colFormLabelSm" placeholder="new password" />
                    <span className={styles.validationError}>{changePasswordFail && changePasswordFail.data.new_password}</span>
                  </Form.Group>
                </Col>
              </Row>
              
              <Row>
                <Col md={6}>
                  <Form.Label>Confirm Password</Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control name="re_new_password" onChange={e=> handleChangePasswrodInput(e)} type="password" className="form-control form-control-sm" id="colFormLabelSm" placeholder="confirm password"/>
                    <span className={styles.validationError}>{changePasswordFail && changePasswordFail.data.re_new_password}</span>
                  </Form.Group>
                </Col>
              </Row>
            </>
          }
        </div>
        {view !== 'view_profile' &&
        <div className="item-link">
          <a href="#0" onClick={ee=>ee.preventDefault()||(view==='edit_profile'?handleUserInfoSubmit():handleChangePassword())}>Submit</a>
          <a href="#0" onClick={ee=>ee.preventDefault()||handleShowProfile()}>Cancel</a>
        </div>
        }

        {update_user_info_success && <UserSnackBar messageKey="userInfo Updated" />}
				{changePasswordSuccess  && <UserSnackBar messageKey="password change success" />}
				{changePasswordFail && changePasswordFail.data.non_field_errors && <UserSnackBar isError={true} messageKey="password_change_failed" message={`Error: ${changePasswordFail.data.non_field_errors[0]}`} />}
      </div>
    )
}

export default Profile
