import axios from 'axios';
import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom'

import SongItem from '../../component/songItem/SongItem';
import { useActiveMusic } from '../../service/activeMusicService';
import { useCart } from '../../service/cartService';
import { useDownloadMidi } from '../../service/downloadMidiService';
import { Synthesia } from '../layout/Layout';

import artistStyles from './Artist.module.scss'


async function queryItems(slug) {
  try {
      const ret = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/music/artist/${slug}/songs/`);
      const data = ret.data;
      return { data: data.results, count: data.count, artist: data.artist };
  } catch(err) {
      const error =  err.response ? err.response  : "networkError"
      console.log(err);
      return { data: [], artist: {}, count: 0 };
  }
}

function Artist() {
  
  const { artistSlug } = useParams();
  const [items, setItems] = useState([])
  const [artist, setArtist] = useState({})
  const fetchStateRef = useRef({ isLoading: false, session: 0 })

  const { addToCart } = useCart();
  const { activeMusicId, setActiveMusic } = useActiveMusic();

  const reset = () => {
    setItems([]);
    fetchStateRef.current = { isLoading: false, session: fetchStateRef.current.session + 1 }
  }

  const fetchData = async (slug) => {
    if (fetchStateRef.current.isLoading) {
      return;
    }
    
    fetchStateRef.current = { isLoading: true, session: fetchStateRef.current.session }
    const session = fetchStateRef.current.session;

    const ret = await queryItems(slug);

    if (fetchStateRef.current.session != session) {
      return;
    }
    fetchStateRef.current = { isLoading: false, session: fetchStateRef.current.session }
    
    if (ret.artist.name) {
      setItems(ret.data);
      setArtist(ret.artist);
    }
  }

  useEffect(async () => {
    reset();
    fetchData(artistSlug)
  }, [artistSlug])

  const { downloadSegment } = useDownloadMidi();

  const getGenreUrl = (genre) => {
    return `/genre-${genre.slug}`;
  }

  return (
    <>
      <Helmet>
        <title>{`Midistax: Songs by ${artist.name} (Piano Midi Downloads)`}</title>
      </Helmet>

      {artist.artwork && <div className={`banner-artitst ${artistStyles.banner}`}>
        <div className={artistStyles.bannerImage} style={{backgroundImage: `url(${artist.artwork.full})`}}></div>
        <div className={artistStyles.bannerInner}>
          <div className="box-title" style={{position:'static'}}>
              <h1>{artist.name}</h1>
              {artist.genres && artist.genres.length>0 && artist.genres.map((genre, index) => <Link key={genre.slug} to={getGenreUrl(genre)}>{genre.name}</Link>)}
          </div>
        </div>
      </div>}
      
      <div className="Genre">
        <div className="Genre-inner">
          <div className="row ">
            <Synthesia isLeft={true} />
            <div className="col-lg-6 mt-lg-0 mt-4">
              <div className="item-body">
                <h4>Discover</h4>
                
                {items.length>0 && items.map((music, index) =>(
                  <SongItem isActive={activeMusicId===music.id} setActiveMusic={setActiveMusic} key={music.id} music={music} addToCart={addToCart} download={downloadSegment} />
                ))}

              </div>
            </div>
            <Synthesia isRight={true} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Artist
