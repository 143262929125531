import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';


function OAuth2Redirect() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const state = searchParams.get('state');
    const code = searchParams.get('code');

    useEffect(async ()=>{

      window.opener.postMessage(
        { state, code },
        window.opener.location
      );

    }, [state, code]);

    return (<></>)
}

export default OAuth2Redirect;
