import { useWindowWidth } from '@react-hook/window-size';
import React, {Fragment, useRef} from 'react'
// import Login from '../container/Login'
import {Link} from 'react-router-dom'
import YouTube from 'react-youtube';
import { mediaURL } from '../../utils'

import styles from '../youtubePreview/YoutubePreview.module.scss'


function getArtistUrl(artist) {
  return `/${artist.slug}`;
}

function getTagUrl(tag) {
  return `/tag-${tag.slug}`;
}

function getSongUrl(music) {
  const artist = music.artists[0].slug;
  return `/${artist}/${music.slug}-${music.id}`;
}

function tryAutoplay(playerRef, videoRef) {

  if (!videoRef.current && playerRef.current.iteration > -1) {
    //probably video was dismounted
    return;
  }

  if (videoRef.current && playerRef.current.player) {
    playerRef.current.player.playVideo();
    return;
  }

  //const maxIterations = 5;
  const maxIterations = 25;
  if (playerRef.current.iteration>=maxIterations) {
    return;
  }

  //const newTimeout = Math.pow(1.5, playerRef.current.iteration+1) * 500;
  const newTimeout = 400;
  playerRef.current = { 
    ...playerRef.current, 
    iteration: playerRef.current.iteration+1,
    timer: setTimeout(function(){
      //tryAutoplay(playerRef, videoRef);
    }, newTimeout),
  };;
}

function SongItemInfo({music, isActive, setActiveMusic, buttons=null, isShowBadges=true, isFull=false}) {
  const imgVariation = 'view';

  const videoRef = useRef(null);

  const emptyPlayerObj = { player: null, timer: null, iteration: -1 };
  const playerRef = useRef(emptyPlayerObj);

  const toggleActiveMusic = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (isActive) {
      setActiveMusic && setActiveMusic(null);
      if (playerRef.current.timer) {
        clearTimeout(playerRef.current.timer);
      }
      playerRef.current = emptyPlayerObj;
    } else {
      setActiveMusic && setActiveMusic(music, videoRef);
      playerRef.current = emptyPlayerObj;
      //tryAutoplay(playerRef, videoRef);
    }
  }

  const songUrl = getSongUrl(music);

  const windowWidth = useWindowWidth();

  const isMobile = windowWidth < 576;

  const onYoutubeReady = (evt) => {
    const nplayer = evt.target;
    const videoData = nplayer.getVideoData();
    const videoId = videoData.video_id;
    if (videoId === music.youtubeId) {
        playerRef.current = { ...playerRef.current, player: nplayer };
        //nplayer.playVideo();
    }
  };
  
  const preview = isActive && !isFull && (<YouTube
    className={styles.responsiveYoutube}
    videoId={music.youtubeId}
    onReady={onYoutubeReady}
    opts={{
      minWidth:'360px', minHeight:'200px',
      playerVars: { rel: 0, modestbranding: 1, showinfo: 0, autoplay: 0, playsinline: 1 },
    }}
  />);

  const H1Tag = isFull ? 'h1' : 'div';
  const H2Tag = isFull ? 'h2' : 'div';
  
  return (
    <div className={`col-sm-${buttons?12:9}${isFull?' song-info-full':''}`}>
        <div className="media">
            <Link to={songUrl} className="d-block">
                <img style={{maxWidth: 103, borderRadius: 10}} onClick={!isFull&&toggleActiveMusic} src={mediaURL(music.artwork[imgVariation].jpeg)} className="img-fluid align-self-center" alt="..."/>
            </Link>
            <div className="media-body">
                <H2Tag className="song-h2-artists mt-0">
                  {/*Martin Garrix, Dubvision*/}
                  {music.artists.map((artist, index) => <Fragment key={artist.slug}>{index>0 && ', '}<Link to={getArtistUrl(artist)}>{artist.name}</Link></Fragment>)}
                  {music.tags && music.tags.length>0 && <> (
                    {music.tags.map((tag, index) => <Fragment key={tag.slug}>{index>0 && ', '}<Link to={getTagUrl(tag)}>{tag.name}</Link></Fragment>)}
                  )</>}
                </H2Tag>
                <H1Tag className="song-h1-title">
                  {/*Starlight (Keep Me..*/}
                  <Link to={songUrl}>{music.title}</Link>
                </H1Tag>

                {/* {isActive && !isMobile && preview} */}

                {isShowBadges && !isFull && <H2Tag className="midi-badge"><Link to={songUrl}>{music.key} <br/> <span>KEY</span></Link></H2Tag>}
                {(isShowBadges || isFull) && <H2Tag className="midi-badge"><Link to={songUrl}>{music.bpm} <br/> <span>BPM</span></Link></H2Tag>}

                {isFull && <H2Tag className="midi-badge"><Link to={songUrl}>Piano Midi File <br/> <span>Format</span></Link></H2Tag>}

                {isFull && <H2Tag className="midi-badge"><Link to={songUrl}>{music.key_info.key} <br/> <span>KEY</span></Link></H2Tag>}
                {isFull &&
                <div>
                  <H2Tag className="midi-badge"><Link to={songUrl}>{music.key_info.sharps} <br/> <span>Sharps</span></Link></H2Tag>
                  <H2Tag className="midi-badge"><Link to={songUrl}>{music.key_info.flats} <br/> <span>Flats</span></Link></H2Tag>
                  {buttons}
                </div>
                }
                {!isFull&& buttons}
            </div>
        </div>
        {isActive && isMobile &&
        <div className="media">
            <div className="media-body" style={{paddingTop: 10}} ref={videoRef}>
              <div className={styles.mobilePreviewWrapper}>
                {preview}
              </div>
            </div>
        </div>
        }
    </div>
  );
}

function SongItem({isFirst, music, type, isActive, setActiveMusic, addToCart, download}) {

    const isDownload = type === 'download';
    const isSingle = type === 'single';

    const priceItemButtons = (
      <div className="download text-end">
        {!isDownload && <a href="#0" onClick={ee=>ee.preventDefault()||(download && download(music))} title="Download 10 sec sample"><img src="/img/download-solid.svg" alt="Download 10 sec sample" className="img-fluid"/></a>}
        {isDownload
            ? <a href="#0" onClick={ee=>ee.preventDefault()||(download && download(music))} title="Download"> Download</a>
            : <a href="#0" onClick={ee=>ee.preventDefault()||(addToCart && addToCart(music))} title="Add to cart"> ${music.price}</a>
        }
      </div>
    );

    return (
      <div className={isFirst?'item':'item mt-3'}>
        {isSingle &&
        <div className="row g-0 song-product-buttons">
          <div className="col-lg-12">
            {priceItemButtons}
          </div>
        </div>
        }
        <div className="row g-0">
          <SongItemInfo music={music} isActive={isActive} setActiveMusic={setActiveMusic} isFull={isSingle} buttons={priceItemButtons}/>
        </div>
      </div>
    )
}

export default SongItem;
export { SongItemInfo };
