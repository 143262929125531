
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import MidiSpinner from '../../component/midiSpinner/MidiSpinner';

import SongItem from '../../component/songItem/SongItem';

import styles from './Artist.module.scss'


async function queryItems() {
  try {
      const ret = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/music/artists/`);
      const data = ret.data;
      return { data: data.results };
  } catch(err) {
      const error =  err.response ? err.response  : "networkError"
      console.log(err);
      return { data: [] };
  }
}

const alphabetStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const miscKey = 'Misc.';
const alphabet = alphabetStr.split('');
alphabet.splice(0, 0, miscKey);

function ArtistIndex() {
  
  const [items, setItems] = useState([]);
  const [activeKey, setActiveKey] = useState('');
  const fetchStateRef = useRef({ isLoading: false, session: 0 })

  const reset = () => {
    setItems([]);
    fetchStateRef.current = { isLoading: false, session: fetchStateRef.current.session + 1 }
  }

  const fetchData = async () => {
    if (fetchStateRef.current.isLoading) {
      return;
    }
    
    fetchStateRef.current = { isLoading: true, session: fetchStateRef.current.session }
    const session = fetchStateRef.current.session;

    const ret = await queryItems();

    if (fetchStateRef.current.session !== session) {
      return;
    }
    fetchStateRef.current = { isLoading: false, session: fetchStateRef.current.session }

    const artDict = ret.data.reduce((acc, cur) => {
      let flt = cur.name[0].toUpperCase();
      if (!flt.match(/[A-Z]/)) {
        flt = 'Misc.';
      }

      acc[flt] = acc[flt] || [];
      acc[flt].push(cur);
      return acc;
    }, {});

    const chunkSize = 8;

    
    let artIndex = [];
    for(let key of alphabet) {
      if (artDict[key]) {
        const cnt = artDict[key].length;
        for (let i=0; i*chunkSize<cnt; i++) {
          const startj = i*chunkSize;
          const endj = Math.min(startj+chunkSize, cnt);
          artIndex.push({
            key: key,
            artists: artDict[key].slice(startj, endj),
          });
        }
      }
    }
    
    setItems(artIndex);
  }

  useEffect(async () => {
    reset();
    fetchData()
  }, []);

  const toggleActiveKey = (key) => {
      setActiveKey(key === activeKey?'':key);
  };

  return (
    <>
      <Helmet>
        <title>{`Midistax: All Artists`}</title>
      </Helmet>

      <div className="Genre">
        <div className="Genre-inner">
          <div className="filter_body">
            <h1>Artist Index</h1>

            {items.length<1 && <MidiSpinner style={{marginTop: 25, marginBottom: 20}} />}

            {/* <select className="form-select" aria-label="Default select example">
                <option selected>Filter artist name by Initial: B</option>
                <option value="1">Filter artist name by Initial: C</option>
                <option value="2">Filter artist name by Initial: D</option>
                <option value="3">Filter artist name by Initial: E</option>
            </select> */}

            <div className={`row ${styles.letters}`}>
                <div className="col-lg-12">
                  {alphabetStr.split('').map(key=>(
                    <button key={key} href="#0" onClick={ee=>toggleActiveKey(key)} className={`midi-badge${key===activeKey?' active':''}`}>{key}</button>
                  ))} 
                  <button href="#0" onClick={ee=>toggleActiveKey(miscKey)} className={`midi-badge${miscKey===activeKey?' active':''}`}>Misc.</button>
                </div>
            </div>

            <div className="row">
              {items.length>0 && items.map(({ key, artists }) => (
                (activeKey==='' || activeKey===key) &&
                <div className="col-lg-3">
                  <div className="item">
                      <h4>{key}</h4>
                      <ul>
                        {artists.map(artist => (<li><Link to={`/${artist.slug}`}>{artist.name}</Link></li>))}
                      </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ArtistIndex
