import { useDispatch, useSelector } from "react-redux";
import { SET_MUSIC } from '../store/action/ActionType'


function useActiveMusic() {
    const { musicId, youtubeId, musicTitle } = useSelector(state => state.music.youtubePreview);
    const dispatch = useDispatch();

    const setActiveMusic = (music, videoRef) => {
      if (music) {
        dispatch({ type: SET_MUSIC, data: { youtubePreview: { musicId: music.id, youtubeId: music.youtubeId, musicTitle: music.title } } });
        if (videoRef) {
          setTimeout(()=>{
            videoRef.current && videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 50);
        }
      } else {
        dispatch({ type: SET_MUSIC, data: { youtubePreview: { musicId: 0, youtubeId: 0, musicTitle: '' } } });
      }
    }

    return { activeMusicId: musicId, activeYoutubeId: youtubeId, activeMusicTitle: musicTitle, setActiveMusic }
}

export { useActiveMusic };