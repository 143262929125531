import React from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {UPDATE_MUSIC_TO_CART_SUCCESS_STATUS, UPDATE_USER_INFO_SUCCESS_STATUS, UPDATE_CHANGE_PASSWORD_SUCCESS_STATUS} from '../../store/action/ActionType'


function UserSnackBar({ message, messageKey, isError}) {

  const horizontal = 'center';
  const vertical = 'top';

  const dispatch = useDispatch()

  // Cart
  if (messageKey==='added to cart') {
    message = message || 'Added to cart'
    closeType(UPDATE_MUSIC_TO_CART_SUCCESS_STATUS);
  }
  if (messageKey==='already added to cart'){
    message = message || 'Already added to cart'
    closeType(UPDATE_MUSIC_TO_CART_SUCCESS_STATUS);
  }
  if (messageKey==='purched allready'){
    message = message || 'This music is already purchased!'
    closeType(UPDATE_MUSIC_TO_CART_SUCCESS_STATUS);
  }

  // Other
  if (messageKey==='userInfo Updated'){
    message = message || 'Updated SuccessFully'
    closeType(UPDATE_USER_INFO_SUCCESS_STATUS);
  }
  if (messageKey==='password change success'){
    message = message || 'Successfully changed Password.'
    closeType(UPDATE_CHANGE_PASSWORD_SUCCESS_STATUS);
  }
  if (messageKey==='password_change_failed'){
    isError = true;
    closeType(UPDATE_CHANGE_PASSWORD_SUCCESS_STATUS);
  }
  
  async function closeType (type){
    await new Promise((resolve) => setTimeout(() => { 
      dispatch({
        type:type
      })
    }, 3000))
  }
  
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={!!message}
      key={vertical + horizontal}
    >
      <SnackbarContent
        aria-describedby="midi-message"
        style={{backgroundColor: isError
          ? 'red'
          : '#C958FF' //'#E649FF'
        }}
        message={
          <span id="midi-message">
            <div>{message}</div>
          </span>
        }
      />
    </Snackbar>
  )
}

export default UserSnackBar