import { Spinner } from "react-bootstrap"

import styles from './MidiSpinner.module.scss'


function MidiSpinner({style}) {
  style = style || {};
  return (
    <div className={styles.spinnerContainer} style={style}><Spinner animation="grow" variant="info" /></div>
  )
}

export default MidiSpinner