import axios from 'axios';
import { Fragment, useState } from 'react';
import { Form } from 'react-bootstrap';

import styles from '../profile/Profile.module.scss'

function Midi2SheetMusic({}) {

  const [file, setFile] = useState(null);
  const [sheets, setSheets] = useState([]);

  const onSubmit = async (evt) => {
    evt.preventDefault();

    const formData = new FormData();
    formData.append(
      "file",
      file,
      file.name
    );
    const resp = await axios.post(process.env.REACT_APP_BACKEND_URL+`/utils/midi2sheetmusic/`, formData);
    
    const sheetsData = resp.data.sheets || [];
    
    setSheets(sheetsData);

    return false;
  }

  return (
    <div className="Profile">
      <h2>Convert Midi file to Sheet Music</h2>

      <Form onSubmit={onSubmit} className={`item ${styles.profileForm}`}>
        <Form.Group controlId="formFileLg" className="mb-3">
          <Form.Label>Upload .mid file to get sheet music</Form.Label>
          <Form.Control type="file" size="lg" name="file" accept=".mid,.midi" onChange={e=>setFile(e.target.files[0])}/>
        </Form.Group>
      </Form>

      <div className="item-link" style={{textAlign: 'center'}}>
        <a href="#0" onClick={onSubmit}>Convert</a>
        <span></span>
      </div>

      {sheets && sheets.length>0 && sheets.map((sheetDataUrl, index) => (
        <Fragment key={index}>
          <hr />
          <img src={sheetDataUrl} alt="Music sheet" style={{maxWidth: '100%'}} />
        </Fragment>
      ))}
    </div>
  );
}

export default Midi2SheetMusic