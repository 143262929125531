import React, { useEffect, useState } from 'react';
import {  Spinner } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux'
import { RequestResetPassword } from '../../store/action/AuthAction'
import { UPDATE_AUTH_STATUS, UPDATE_FORGET_PASSWORD_REQUEST_STATUS } from '../../store/action/ActionType'

import MidiModal from './MidiModal';
import ModalMessage from './ModalMessage';
import MidiSpinner from '../../component/midiSpinner/MidiSpinner';
import useActiveModal from '../../service/activeModalService';
import { useDownloadMidi } from '../../service/downloadMidiService';
import { Fragment } from 'react';

import styles from './Modals.module.scss';


const Download = ({mainMusic}) => {

  let { setActiveModal, activeModalCtx: music } = useActiveModal();
  music = mainMusic || music;
  const { downloadSegment } = useDownloadMidi();
  const dispatch = useDispatch()

  const handleOnCancel = () => {
    setActiveModal('')
    dispatch({ type: UPDATE_AUTH_STATUS })
    dispatch({type:UPDATE_FORGET_PASSWORD_REQUEST_STATUS})
  }

  const handleDownload = (evt) => {
    evt.preventDefault();
    handleOnCancel();
    downloadSegment(music, true);
  };

  useEffect(()=>{
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
    }
  });

  return (
    <MidiModal onClose={handleOnCancel} className={styles.download}>
      <h3>Download free 10 sec. midi</h3>
      
      {/* <!-- Midistax Pop-Up --> */}
      {process.env.REACT_APP_BACKEND_URL &&
      <ins className="adsbygoogle"
          style={{display:'block'}}
          data-ad-client={process.env.REACT_APP_ADSENSE_CLIENT}
          data-ad-slot="8903576354"
          data-ad-format="auto"
          data-full-width-responsive="true"
      ></ins>
      }

      <p>
        {music.artists.map((artist, index) => <Fragment key={artist.slug}>{index>0 && ', '}{artist.name}</Fragment>)} - {music.title}
      </p>

      <form>
        <button onClick={handleDownload} type="submit" className="btn btn-primary">Download</button>
      </form>
    </MidiModal>
  );
};

export default Download