import React from 'react'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import MidiBanner from '../../component/midiBanner/MidiBanner';


function AboutPage() {
  return (
    <>
      <Helmet></Helmet>

      <MidiBanner isAboutPage={true}/>

      {/* <!-- Mission Statement start --> */}
      <div className="Mission-Statement">
          <h2>Mission Statement</h2>
          <p>Midistax is here to deliver a growing wide selection of MIDI files in piano mid. format containing melody and chords.  The benefits of our MIDI files make them greatly diverse in the ways you can use them to help in your own passions in music as a creative. Check out below some of the awesome ways you can maximise value of Midistax midi files. Midistax also offers the our users options to order songs they cannot find through our search result system.</p>
      </div>
      {/* <!-- Mission Statement end --> */}
      {/* <!-- Music Producers   start --> */}
      <div className="Music-Producers text-lg-left text-center">
          {/* <!-- row start --> */}
          <div className="row">
              {/* <!-- single item start --> */}
              <div className="col-lg-5 align-self-center">
                  <div className="item-left">
                      <img src="/img/about/img1.png" alt="img" className="img-fluid"/>
                  </div>
              </div>
              {/* <!-- single item end --> */}
              {/* <!-- single item start --> */}
              <div className="col-lg-7 mt-lg-0 mt-5 align-self-center">
                  <div className="item-right">
                      <h4> Music Producers  </h4>
                      <p>Music producers can make great use of midi files by using chords and or melodies as inspiration for their own music compositions in DAW applications (Digital Audio Workstations) such as Ableton Live , Apple Logic Pro, Image-Line FL Studio, Steinberg Cubase. </p>
                  </div>
              </div>
              {/* <!-- single item end --> */}
              
          </div>
          {/* <!-- row end --> */}
      </div>
      {/* <!-- Music Producers  end --> */}
      {/* <!-- Music Producers   start --> */}
      <div className="Music-Producers Music-Producers2 text-lg-left text-center">
          {/* <!-- row start --> */}
          <div className="row">
              {/* <!-- single item start --> */}
              <div className="col-lg-5 d-lg-none d-block  align-self-center">
                  <div className="item-left">
                      <img src="/img/about/img2.png" alt="img" className="img-fluid"/>
                  </div>
              </div>
              {/* <!-- single item end --> */}
              {/* <!-- single item start --> */}
              <div className="col-lg-7 mt-lg-0 mt-5 align-self-center">
                  <div className="item-right">
                      <h4> Pianist , Musicians  </h4>
                      <p>Musicians from Pianist, Guitarist & all love the affordable ability to easily open MIDI files in Sheet Music applications such as Musescore and conversion services such as our own free one here: <Link to="/midi-to-sheet-music">https://midistax.com/midi-to-sheet-music</Link></p>
                      <p>Perfect way to traditionally learn music or using modern visual learning applications such as Synthesia.</p>
                  </div>
              </div>
              {/* <!-- single item end --> */}
                {/* <!-- single item start --> */}
              <div className="col-lg-5 d-lg-block d-none  align-self-center">
                  <div className="item-left">
                      <img src="/img/about/img2.png" alt="img" className="img-fluid"/>
                  </div>
              </div>
              {/* <!-- single item end --> */}
              
          </div>
          {/* <!-- row end --> */}
      </div>
      {/* <!-- Music Producers  end --> */}
      {/* <!-- Music Producers   start --> */}
      <div className="Music-Producers text-lg-left text-center">
          {/* <!-- row start --> */}
          <div className="row">
              {/* <!-- single item start --> */}
              <div className="col-lg-5  align-self-center">
                  <div className="item-left">
                      <img src="/img/about/img3.png" alt="img" className="img-fluid"/>
                  </div>
              </div>
              {/* <!-- single item end --> */}
              {/* <!-- single item start --> */}
              <div className="col-lg-7 mt-lg-0 mt-5 align-self-center">
                  <div className="item-right">
                      <h4> DJ’s (Disc Jockey)</h4>
                      <p>DJ’s can find great use in MIDI files within the preperation setup for remixing, finding harmonics. DJ’s are using a wide range tools from DAW’s, to DJ software such  Serato DJ Pro, Native Instruments Traktor Pro 3  and Pioneer Rekordbox DJ.</p>
                  </div>
              </div>
              {/* <!-- single item end --> */}
              
          </div>
          {/* <!-- row end --> */}
      </div>
      {/* <!-- Music Producers  end -->     */}
    </>
  )
}

export default AboutPage
