import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from "react-helmet";
import { requestMusic } from '../../store/action/AdminAction'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import SongItem from '../../component/songItem/SongItem'
import { useCart } from '../../service/cartService'
import { useActiveMusic } from '../../service/activeMusicService'
import { Nav, Row, Spinner } from 'react-bootstrap'
import ArtistItem from './ArtistItem';
import useActiveModal from '../../service/activeModalService';
import SongSearchItem from '../../component/songSearchItem/SongSearchItem';
import { useDownloadMidi } from '../../service/downloadMidiService';
import { Synthesia } from '../layout/Layout';
import MidiSpinner from '../../component/midiSpinner/MidiSpinner';

const guestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
}

async function searchItems(query, kind, page) {
  //query = encodeURIComponent(query);

  let url = `${process.env.REACT_APP_BACKEND_URL}/music/searchMusic/`

  try {
      const ret = await axios.post(url, { query, page, kind }, guestConfig);
      const data = ret.data;
      return { data: data.results, hasNext: !!data.next, count: data.count };
  } catch(err) {
      const error =  err.response ? err.response  : "networkError"
      console.log(err);
      return { data: [], hasNext: false };
  }
}

function ScrollableContainer({items, emptyText, fetchItems, hasMore, show, renderItem}) {

  const renderWrap = (item, index) => {
    //debugger;
    return renderItem(item, index);
  }

  return (
    <div className={`tab-pane fade ${show?'show active':''}`} role="tabpanel" aria-labelledby="pills-search-tab">
      <div className="row ">
        <Synthesia isLeft={true} />
        <div className="col-lg-6">
          <div className="item-body item-body2">
            {items && items.length < 1 && !hasMore
              ? <p style={{ padding: '30px 0px',  textAlign: 'center'}}>{emptyText}</p>
              :
                <InfiniteScroll
                  style={{overflow: 'visible'}}
                  dataLength={items && items.length ? items.length : 0} //This is important field to render the next data
                  next={fetchItems}
                  hasMore={show && hasMore}
                  loader={<MidiSpinner style={{marginTop: 25, marginBottom: 20}} />}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      {items.length>5 && <b>Yay! You have seen it all</b>}
                    </p>
                  }
                  scrollableTarget=""
                >
                  {items && items.map(renderWrap)}
                </InfiniteScroll>
            }
          </div>
          
        </div>
        <Synthesia isRight={true} />
      </div>
    </div>
  )
}

function SearchMusic() {

  const [kind, setKind] = useState('song');

  const emptyState = { page: 0, searchNext: true, items: []};

  const [state, setState] = useState({ song: emptyState, artist: emptyState, query: '' });

  const fetchStateRef = useRef({ song: {isLoading: false, session: 0}, artist: {isLoading: false, session: 0} })

  const { song: query } = useParams();

  const reset = () => {
    setState({ song: emptyState, artist: emptyState });

    fetchStateRef.current = {
      song: {isLoading: false, session: fetchStateRef.current.song.session + 1},
      artist: {isLoading: false, session: fetchStateRef.current.artist.session + 1},
    }
  }

  const { addToCart } = useCart()
  const { activeMusicId, setActiveMusic } = useActiveMusic();
  

  const fetchData = async (kind) => {
    //debugger;
    if (fetchStateRef.current[kind].isLoading) {
      return;
    }

    fetchStateRef.current[kind].isLoading = true;
    const session = fetchStateRef.current[kind].session;

    const ret = await searchItems(query, kind, state[kind].page + 1);

    if (fetchStateRef.current[kind].session != session) {
      return;
    }
    fetchStateRef.current[kind].isLoading = false;

    const searchNext = state[kind].searchNext && !!ret.hasNext;
    if (ret.data.length) {
      setState({...state, [kind]: { page: state[kind].page + 1, searchNext, items: [...state[kind].items, ...ret.data] }})
    } else {
      setState({...state, [kind]: { page: state[kind].page + 1, searchNext, items: state[kind].items }})
    }
  }

  const fetchSongs = async () => {
    await fetchData('song');
  }

  const fetchArtists = async () => {
    await fetchData('artist');
  }

  useEffect(async () => {
    reset();
    await fetchData(kind);
  }, [query])


  const { accessToken } = useSelector(state => state.auth)

  const dispatch = useDispatch()

  const { setActiveModal } = useActiveModal();

  const requestSong = (music) => {
    if (accessToken) {
      dispatch(requestMusic(music.spotifyId, query, accessToken))
    } else {
      setActiveModal('login')
    }
  }

  const switchMode = (isArtists) => {
    const oldKind = kind;
    const newKind = isArtists?'artist':'song';
    setKind(newKind);

    if (newKind != oldKind && state[newKind].items.length < 1 && state[newKind].searchNext) {
      fetchData(newKind);
    }
  }

  const { searchItemStatus } = useSelector(state => state.music)
  const getSongStatus = (item) => {
    return searchItemStatus[item.spotifyId] || 'new';
  }

  const { downloadSegment } = useDownloadMidi();

  return (
    <>
      <Helmet>
        <title>Midistax: Search for {query}</title>
        <meta name="description" content={`Search results for '${query}', see Song Key, BPM, Chords - Download Synthesia Piano Midi Files`} />
      </Helmet>

      <div className="Genre">
        <div className="Genre-inner">
          <div className="Search_Results">
              <div className="box">
                
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2>Search Results for: {query}</h2>
                    {/* <ul>
                      <li>54 Midi Files found</li>
                      <li>
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Accordion Item #1
                        </button>
                        
                      </li>
                      <li>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Accordion Item #3
                        </button>
                      </li>
                    </ul> */}
                    
                    
                    {/* <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="search_link_all">
                          <ul>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="search_link_all">
                          <ul>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                            <li><a href="">Lorem Ipsum</a></li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                
              </div>
              <div className="search-link-item">
                <ul>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                </ul>
              </div>
          </div>

          <div className="nav_body">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              {/* <li>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  Sort by: New midi files
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a className="dropdown-item" href="#">Item 1</a></li>
                    <li><a className="dropdown-item" href="#">Item 1</a></li>
                    <li><a className="dropdown-item" href="#">Item 1</a></li>
                    <li><a className="dropdown-item" href="#">Item 1</a></li>
                    <li><a className="dropdown-item" href="#">Item 1</a></li>
                  </ul>
                </div>
              </li> */}
              <li className="nav-item" role="presentation">
                <button onClick={e=>switchMode(false)} className={`nav-link ${kind==='song'?'active':''}`} type="button" role="tab" aria-controls="pills-home" aria-selected="true">Songs</button>
              </li>
              <li className="nav-item" role="presentation">
                <button onClick={e=>switchMode(true)} className={`nav-link ${kind==='artist'?'active':''}`} type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Artists</button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <ScrollableContainer show={kind==='song'} fetchItems={fetchSongs} items={state.song.items} hasMore={state.song.searchNext} emptyText="No music found" renderItem={(item, index) => (
                item.slug 
                  ? <SongItem key={index} isFirst={index<1} isActive={activeMusicId===item.id} setActiveMusic={setActiveMusic} music={item} addToCart={addToCart} download={downloadSegment} />
                  : <SongSearchItem key={index} isFirst={index<1} music={item} status={getSongStatus(item)} requestSong={requestSong} addToCart={addToCart} />
              )}/>
              <ScrollableContainer show={kind==='artist'} fetchItems={fetchArtists} items={state.artist.items} hasMore={state.artist.searchNext} emptyText="No artist found" renderItem={(item, index) => (
                <ArtistItem key={index} isFirst={index<1} artist={item} />
              )}/>
            </div>
          </div>

        </div>
      </div>      
    </>
  )
}

export default SearchMusic


























