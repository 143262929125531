import React, { useState, useEffect, Fragment, useRef } from 'react'
import { Spinner } from 'react-bootstrap';
import SongItem from '../songItem/SongItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios'
import { useCart } from '../../service/cartService';
import { Link, NavLink, useParams } from 'react-router-dom';
import { useActiveMusic } from '../../service/activeMusicService';

import { useDownloadMidi } from '../../service/downloadMidiService';
import MidiSpinner from '../midiSpinner/MidiSpinner';


async function queryItems(tagmode, slug, page) {
    try {
        const url = tagmode
          ? `${process.env.REACT_APP_BACKEND_URL}/music/tag/${slug}/${page}/`
          : `${process.env.REACT_APP_BACKEND_URL}/music/playlist/${slug}/${page}/`
        const ret = await axios.get(url);
        const data = ret.data;
        return { data: data.results, hasNext: !!data.next, count: data.count, name: data.name };
    } catch(err) {
        const error =  err.response ? err.response  : "networkError"
        console.log(err);
        return { data: [], hasNext: false, name: '' };
    }
}

function Playlist({ slug, infinite, ads, tagmode }) {

    tagmode = tagmode ? true : false;

    const [page, setPage] = useState(0)
    const [searchNext, setSearchNext] = useState(true)
    const [items, setItems] = useState([])
    const [header, setHeader] = useState('')

    const fetchStateRef = useRef({ isLoading: false, session: 0 })

    const reset = () => {
      setPage(0);
      setSearchNext(true);
      setItems([]);

      fetchStateRef.current = { isLoading: false, session: fetchStateRef.current.session + 1 }
    }

    const { addToCart } = useCart()
    const { activeMusicId, setActiveMusic } = useActiveMusic();

    const { slug: curSlug } = useParams()

    if (!slug) {
        slug = curSlug;
    }

    const url = tagmode ? `/tag-${slug}` : `/songs/${slug}`;

    const fetchData = async (tagmode, slug, curPage, curItems) => {
      if (fetchStateRef.current.isLoading) {
        return;
      }
      
      fetchStateRef.current = { isLoading: true, session: fetchStateRef.current.session }
      const session = fetchStateRef.current.session;

      const ret = await queryItems(tagmode, slug, curPage + 1);

      if (fetchStateRef.current.session != session) {
        return;
      }
      fetchStateRef.current = { isLoading: false, session: fetchStateRef.current.session }
      
      if (!ret.hasNext || !infinite) {
          setSearchNext(false)
      }
      if (ret.data.length) {
        setPage(curPage + 1)
        setItems([...curItems, ...ret.data])
        if (header != ret.name) {
          setHeader(ret.name + ' Downloads')
        }
      }
    }

    useEffect(async () => {
      reset();
      fetchData(tagmode, slug, 0, [])
    }, [tagmode, slug])

    const { downloadSegment } = useDownloadMidi();

    return (
      <div className="item-body">
        <h4>{header}</h4>
        <InfiniteScroll
            dataLength={items && items.length ? items.length : 0} //This is important field to render the next data
            next={(infinite || page===0)?(() => fetchData(tagmode, slug, page, items)):null}
            hasMore={searchNext}
            loader={<MidiSpinner style={{marginTop: 25, marginBottom: 20}} />}
            endMessage={infinite && page>1 &&
                <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                </p>
            }
            scrollableTarget=""
        >
            {items &&
              items.map((music, index) => (
                <Fragment key={index}>
                  <SongItem isFirst={index<1} isActive={activeMusicId===music.id} setActiveMusic={setActiveMusic} music={music} addToCart={addToCart} download={downloadSegment} />
                  {ads && (index===9 || index===19 || index===29) && (
                    <div className="cardforadd">
                      <p className="add_BOX">
                        <div>
                          <div dangerouslySetInnerHTML={{__html: ads}} />
                        </div>
                      </p>
                    </div>
  
                  )}
                </Fragment>
              ))
            }
        </InfiniteScroll>
        {!infinite && <div className="Load-More text-center mt-4">
            <Link to={url}>Load More</Link>
        </div>}
      </div>
    )
}

export default Playlist
