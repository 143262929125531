import axios from 'axios'
import {
    GET_TOP_LIST_START,
GET_TOP_LIST_SUCCESS,
GET_TOP_LIST_FAIL,

GET_LATEST_MUSIC_START,
GET_LATEST_MUSIC_SUCCESS,
GET_LATEST_MUSIC_FAIL,

GET_ALL_MUSIC_START,
GET_ALL_MUSIC_SUCCESS,
GET_ALL_MUSIC_FAIL,

ADD_MUSIC_TO_CART_START,
ADD_MUSIC_TO_CART_SUCCESS,
ADD_MUSIC_TO_CART_FAIL,

COUNT_CARTED_MUSIC_START,
COUNT_CARTED_MUSIC_SUCCESS,
COUNT_CARTED_MUSIC_FAIL,

GET_CART_MUSIC_LIST_START,
GET_CART_MUSIC_LIST_SUCCESS,
GET_CART_MUSIC_LIST_FAIL,

GET_PERCHESED_MUSIC_START,
GET_PERCHESEMUSIC_SUCCESS,
GET_PERCHESED_MUSIC_FAIL,

GET_PERCHESED_MUSIC_LIST_START,
GET_PERCHESEDUSIC_LIST_SUCCESS,
GET_PERCHESED_MUSIC_LIST_FAIL,

GET_SINGELAGE_SONGE_START,
GET_SINGELAGE_SONGE_SUCCESS,
GET_SINGELAGE_SONGE_FAIL,

SET_MUSIC,

} from './ActionType'


export const getPlaylists = () => async (dispatch) => {
  try {
    await axios.get(process.env.REACT_APP_BACKEND_URL+"/music/playlists/").then((res) => {
        dispatch({
            type: SET_MUSIC,
            data : { playlists: res.data.results }
        })
      })
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
    console.log('Get playlist error: ' + error);
    // dispatch({
    //   type:GET_TOP_LIST_FAIL,
    //   data:error
    // })
  }
};


export const getTopMusic = () => async (dispatch) => {
    try {
        dispatch({type:GET_TOP_LIST_START})

            await axios.get(process.env.REACT_APP_BACKEND_URL+"/music/playlist/top/1/0/").then((res) => {
                dispatch({
                    type:GET_TOP_LIST_SUCCESS,
                    data : res.data
                })
      
              })
        
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:GET_TOP_LIST_FAIL,
        data:error
      })
    }

};


export const getLatestMusic = () => async (dispatch) => {
    try {
        dispatch({type:GET_LATEST_MUSIC_START})
  
        await axios.get(process.env.REACT_APP_BACKEND_URL+"/music/latestmusic/").then((res) => {
            dispatch({
                type:GET_LATEST_MUSIC_SUCCESS,
                data : res.data
            })
  
          })
    
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:GET_LATEST_MUSIC_FAIL,
        data:error
      })
    }

};


export const getAllMusic = (title, p) => async (dispatch) => {
    let value = "'"
    if (title){
      value=title
    }
    try {
        dispatch({type:GET_ALL_MUSIC_START})

            await axios.get(process.env.REACT_APP_BACKEND_URL+`/music/listwithfilter/${value}?p=${p}`).then((res) => {
             
            dispatch({
                    type:GET_ALL_MUSIC_SUCCESS,
                    data : res.data
                })
      
              })
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:GET_ALL_MUSIC_FAIL,
        data:error
      })
    }

};

export const AddMusicToCart = (id, spotifyId, config) => async (dispatch) => {
    try {
        dispatch({type:ADD_MUSIC_TO_CART_START})
        await axios.post(process.env.REACT_APP_BACKEND_URL+`/cart/addremovetocart/`, { id, spotifyId }, config).then((res) => {
        dispatch({
          type:ADD_MUSIC_TO_CART_SUCCESS,
          data:res.data
        })
        dispatch(CountCartedMusic(config))

        })
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:ADD_MUSIC_TO_CART_FAIL,
        data:error
      })
    }

};

export const RemoveMusicFromCart = (music, config) => async (dispatch) => {
  try {
    const isOrder = !music.slug;
    const id = isOrder ? music.spotifyId : music.id;
    await axios.post(process.env.REACT_APP_BACKEND_URL+`/cart/removeitemfromcart/`, { id, isOrder }, config).then((res) => {

      dispatch(CartItems(config))
      dispatch(CountCartedMusic(config))

    });
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
  }
};

export const CountCartedMusic = (config) => async (dispatch) => {
    try {
        dispatch({type:COUNT_CARTED_MUSIC_START})
      await axios.get(process.env.REACT_APP_BACKEND_URL+`/cart/addtocartitemscount/`, config).then((res) => {
          dispatch({
              type:COUNT_CARTED_MUSIC_SUCCESS,
              data : res.data.cart_item_count,
          });
          dispatch({
            type: SET_MUSIC,
            data: { freshDownloadsCount: res.data.fresh_download_count },
          });
        })
        
    } catch (err) {
      const error =  err.response ? err.response  : "networkError"
      dispatch({
        type:COUNT_CARTED_MUSIC_FAIL,
        data:error
      })
    }
};


export const CartItems = (config, cartData) => async (dispatch) => {
  try {
      dispatch({type:GET_CART_MUSIC_LIST_START})
      var promise;
      if (cartData && !config.headers.Authorization) {
        promise = axios.post(process.env.REACT_APP_BACKEND_URL+`/cart/songs/`, { ...cartData }, config)
      } else if (cartData) {
        promise = axios.post(process.env.REACT_APP_BACKEND_URL+`/cart/addtocartitems/`, { ...cartData }, config)
      } else {
        promise = axios.get(process.env.REACT_APP_BACKEND_URL+`/cart/addtocartitems/`, config)
      }
      await promise.then((res) => {
        dispatch({
          type:GET_CART_MUSIC_LIST_SUCCESS,
          data:res.data
        })
        dispatch({
          type:COUNT_CARTED_MUSIC_SUCCESS,
          data:res.data.music.length + res.data.orders.length
        })
      })
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
    dispatch({
      type:GET_CART_MUSIC_LIST_FAIL,
      data:error
    })
  }

};

export const purchedMusicList = (config) => async (dispatch) => {
  try {
      dispatch({type:GET_PERCHESED_MUSIC_LIST_START})
      await axios.get(process.env.REACT_APP_BACKEND_URL+`/music/ownedmusics/`, config).then((res) => {
      dispatch({
        type:GET_PERCHESEDUSIC_LIST_SUCCESS,
        data:res.data
      })

      })
      
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
    dispatch({
      type:GET_PERCHESED_MUSIC_LIST_FAIL,
      data:error
    })
  }

};

export const purchMusic = (config, data, actions) => async (dispatch) => {
  try {

      dispatch({type:GET_PERCHESED_MUSIC_START})

      return axios.post(process.env.REACT_APP_BACKEND_URL+`/orders/paypal/capture/`, { orderID: data.orderID }, config).then(function(orderData) {
          orderData = orderData.data

          if (orderData.status == 'success') {
            // Successful capture! For demo purposes:
            dispatch({
              type:GET_PERCHESEMUSIC_SUCCESS,
            })

            dispatch(purchedMusicList(config))
            dispatch(CartItems(config))
            dispatch(CountCartedMusic(config))
              
          } else if (orderData.status == 'restart') {
            actions.restart(); // Recoverable state, per:
            // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
            dispatch({
              type:GET_PERCHESED_MUSIC_FAIL,
              data:'Restarted'
            })
          } else {
            var msg = orderData.error || 'Sorry, your transaction could not be processed.';
            dispatch({
              type:GET_PERCHESED_MUSIC_FAIL,
              data:msg
            })
          }
      });      
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
    dispatch({
      type:GET_PERCHESED_MUSIC_FAIL,
      data:error
    })
  }
};

export const getSingeleSong = (id) => async (dispatch) => {
  try {

      dispatch({type:GET_SINGELAGE_SONGE_START})

      await axios.get(process.env.REACT_APP_BACKEND_URL+`/music/singleSong/${id}`).then((res) => {

      dispatch({
        type:GET_SINGELAGE_SONGE_SUCCESS,
        data: res.data
      })



      })
      
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
    dispatch({
      type:GET_SINGELAGE_SONGE_FAIL,
      data:error
    })
  }

};