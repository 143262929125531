import React, { useState } from 'react';
import {  Spinner } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux'
import { RequestResetPassword } from '../../store/action/AuthAction'
import { UPDATE_AUTH_STATUS, UPDATE_FORGET_PASSWORD_REQUEST_STATUS } from '../../store/action/ActionType'

import MidiModal from './MidiModal';
import ModalMessage from './ModalMessage';
import MidiSpinner from '../../component/midiSpinner/MidiSpinner';

import styles from './Modals.module.scss';


const ResetPassword = ({ setActiveModal }) => {

  const dispatch = useDispatch()

  const {  accessToken, startForgetPasswordRequest, forgetPasswordRequestSuccess, forgetPasswordRequestFail } = useSelector(state => state.auth)
  const [forgetPasswordEmail, setForgetPasswordEmail] = useState('')

  const handleOnCancel = () => {
    setActiveModal('')
    dispatch({ type: UPDATE_AUTH_STATUS })
    dispatch({type:UPDATE_FORGET_PASSWORD_REQUEST_STATUS})
  }

  const handleForgetPasswordRequestSubmit = (e) => {
    e.preventDefault()
  
    dispatch(RequestResetPassword(forgetPasswordEmail))
  }

  { accessToken && goToHome() }
  async function goToHome() {
    await new Promise((resolve) => setTimeout(() => {
      const access_token = localStorage.getItem('access_token')
      if (access_token) {
        setActiveModal('')
        dispatch({ type: UPDATE_AUTH_STATUS })
        dispatch({type:UPDATE_FORGET_PASSWORD_REQUEST_STATUS})
      }

    }))
  }

  if (forgetPasswordRequestSuccess) {
    return <ModalMessage onClose={handleOnCancel} isSuccess={true}>Please check your email to reset password</ModalMessage>
  }

  return (
    <MidiModal onClose={handleOnCancel} className={styles.resetpassword}>
      <h3>Reset password</h3>
      <p>enter your email to get reset password link</p>
      <form onSubmit={handleForgetPasswordRequestSubmit}>
        <div className="mb-3 from-item">
          <label htmlFor="email" className="form-label"><img src="/img/mail.png" alt="img" className="img-fluid" /></label>
          <input value={forgetPasswordEmail} onChange={e => setForgetPasswordEmail(e.target.value)} type="email" name="email" id="email" className="form-control" placeholder="Email" required />
          <span style={{ color: 'red', fontSize: 11, float: 'left' }}>{forgetPasswordRequestFail && forgetPasswordRequestFail.data && forgetPasswordRequestFail.data.email && forgetPasswordRequestFail.data.email}</span>
        </div>
        
        {startForgetPasswordRequest
          ? <MidiSpinner />
          : <button onClick={handleForgetPasswordRequestSubmit} type="submit" className="btn btn-primary">Submit</button>
        }
      </form>
    </MidiModal>
  );
};

export default ResetPassword