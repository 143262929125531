import React from 'react'

import { NavButtons } from '../navbar/MidiNavbar';

function MobileFooter() {
  return (
    <div className="mobile-bottom-menu fixed-bottom text-center d-lg-none d-block">
      <div className="container-fluid">
        <NavButtons />
      </div>
    </div>
  )
}

export default MobileFooter
