import { useDispatch, useSelector } from "react-redux";
import { SET_MUSIC } from '../store/action/ActionType'

function useNavExpanded() {

  const { isNavExpanded } = useSelector(state => state.music)
  const dispatch = useDispatch();

  const setNavExpanded = (isExpanded) => {
    dispatch({type: SET_MUSIC, data: {isNavExpanded: isExpanded}})
  }

  return { isNavExpanded, setNavExpanded }
}

export default useNavExpanded;