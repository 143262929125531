import { useWindowWidth } from '@react-hook/window-size';
import React from 'react'
import YouTube from 'react-youtube';

import { useActiveMusic } from '../../service/activeMusicService';

import styles from './YoutubePreview.module.scss'


function YoutubePreview() {

  const { activeYoutubeId, activeMusicTitle, setActiveMusic } = useActiveMusic();
  const windowWidth = useWindowWidth();

  if (!activeYoutubeId) {
    return null;
  }
  

  const isMobile = windowWidth < 576;
  if (isMobile) {
    return null;
  }

  const handleClose = () => {
    setActiveMusic(null);
  }

  const onYoutubeReady = (evt) => {
      const nplayer = evt.target;
      const videoData = nplayer.getVideoData();
      const videoId = videoData.video_id;
      if (videoId === activeYoutubeId) {
          //nplayer.playVideo();
      }
  };

  return (
      <div className={styles.container}>
        <div className="modal-content">
          <div className="modal-header">
            <button onClick={handleClose} type="button" className="btn-close" aria-label="Close"></button>
          </div>
          <div className="modal-body" style={{minWidth: 450}}>
            {activeYoutubeId &&
            <div>
              <YouTube
                className={styles.responsiveYoutube}
                videoId={activeYoutubeId}
                onReady={onYoutubeReady}
                opts={{
                  minWidth:'450px', minHeight:'250px',
                  playerVars: { rel: 0, modestbranding: 1, showinfo: 0, autoplay: 0 },
                }}
              />
            </div>
            }
          </div>
        </div>
      </div>
  )
}

export default YoutubePreview
