import { useDispatch, useSelector } from "react-redux";
import { ADD_MUSIC_TO_CART_SUCCESS, COUNT_CARTED_MUSIC_SUCCESS, GET_CART_MUSIC_LIST_SUCCESS, SET_MUSIC } from '../store/action/ActionType'
import { AddMusicToCart} from '../store/action/MusicAction'

import ls from 'local-storage'
import { getAccessToken } from "../utils";

function getLocalCart() {
  const cartDataStr = ls('cart');
  let cartData = cartDataStr && JSON.parse(cartDataStr) || { midis: [], songs: [] };
  return cartData;
}

function getItemId(music) {
  const arrName = music.slug ? 'midis' : 'songs';
  const id = music.slug ? music.id : music.spotifyId;

  return [id, arrName]
}

function addToLocalCart(dispatch, music) {
  const cartData = getLocalCart();
  const [id, arrName] = getItemId(music)
  if (cartData[arrName].filter(x => x === id).length) {
    dispatch({ type:ADD_MUSIC_TO_CART_SUCCESS, data: 'already added to cart' });
  } else {
    cartData[arrName].push(id);
    ls('cart', JSON.stringify(cartData));
    dispatch({ type:ADD_MUSIC_TO_CART_SUCCESS, data: 'added to cart' });
    dispatch({ type:COUNT_CARTED_MUSIC_SUCCESS, data: cartData.midis.length + cartData.songs.length });
  }
}

function refreshCartCount(dispatch) {
  const cartData = getLocalCart();
  dispatch({ type:COUNT_CARTED_MUSIC_SUCCESS, data: cartData.midis.length + cartData.songs.length });
}

function removeFromLocalCart(dispatch, music) {
  debugger;
  const cartData = getLocalCart();
  const [id, arrName] = getItemId(music)
  cartData[arrName] = cartData[arrName].filter(x => x !== id);
  ls('cart', JSON.stringify(cartData));
  dispatch({ type:COUNT_CARTED_MUSIC_SUCCESS, data: cartData.midis.length + cartData.songs.length });
}

function useCart() {

    const { accessToken} = useSelector(state => state.auth);
    const { get_user_info_success} = useSelector(state => state.user);
    const dispatch = useDispatch();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAccessToken(),
      },
    };

    const addToCart = (music) =>{
      if(!accessToken){
        addToLocalCart(dispatch, music)
      }else{  
        const id = music.slug ? music.id : null;
        const spotifyId = music.slug ? null : music.spotifyId;
        get_user_info_success && !get_user_info_success.is_superuser && dispatch(AddMusicToCart(id, spotifyId, config))
      }
    }

    return { addToCart }
}

export { useCart, refreshCartCount, getLocalCart, removeFromLocalCart };