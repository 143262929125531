import axios from "axios";
import fileDownload from "js-file-download";
import { useDispatch } from "react-redux";
import { CountCartedMusic } from "../store/action/MusicAction";
import { getAccessToken } from "../utils";
import useActiveModal from "./activeModalService";


function download(music, isSegment, accessToken) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  const downloadUrl = process.env.REACT_APP_BACKEND_URL+(isSegment?`/music/downloadsegment/`:`/music/downloadmusic/`)
  axios.post(downloadUrl + music.id + `/`, {}, { ...config, responseType: 'blob' }).then((response) => {
    const segSuffix = isSegment ? '.30-sec-sample' : '';
    const fileName = response.headers['x-suggested-filename'] || `Midistax - ${music.title} - ${music.author} (in the key of ${music.key})${segSuffix}.MIDISTAX.COM.mid`;
    fileDownload(response.data, fileName);

    // if (!isSegment) {
    //   dispatch(CountCartedMusic(config));
    // }
  });
}


function useDownloadMidi() {
  const { setActiveModal } = useActiveModal();
  const handleDownload = (music, isSegment, skipAds, isMain) => {
    if (isSegment && !skipAds) {
      setActiveModal(isMain?'download-main':'download', music);
      return;
    }

    const accessToken = getAccessToken();
    if (accessToken) {
      download(music, isSegment, accessToken);
    } else {
      setActiveModal('login');
    }
  }
  return { downloadFull: (music) => handleDownload(music, false), downloadSegment: (music, skipAds, isMain) => handleDownload(music, true, skipAds, isMain) }
}


export { useDownloadMidi };