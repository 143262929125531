import React, { useState } from 'react';
import {  Form, NavLink, Spinner } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux'
import { Registration } from '../../store/action/AuthAction'
import { UPDATE_REGISTRATION_STATUS } from '../../store/action/ActionType'

import MidiModal from './MidiModal';
import { Link, useHistory } from 'react-router-dom';
import ModalMessage from './ModalMessage';
import SocialLogin from '../socialLogin/SocialLogin';
import MidiSpinner from '../../component/midiSpinner/MidiSpinner';

import styles from './Modals.module.scss'

const Registraion = ({ setActiveModal }) => {

  const dispatch = useDispatch()
  const history = useHistory();
  const [hidePassword1, setHidePassword1] = useState('password')
  const [hidePassword2, setHidePassword2] = useState('password')
  const { registrationStart, registrationSuccessStatus, registrationFail } = useSelector(state => state.auth)

  const [registrationInfo, setRegistrationInfo] = useState({
    userName: '',
    email: '',
    password: '',
    re_password: '',
    is_agreed: false,
  })

  const handleShowPassword1 = () =>{
    if (hidePassword1 === 'password'){
      setHidePassword1('text')
    }else{
      setHidePassword1('password')
    }
  }
  const handleShowPassword2 = () =>{
    if (hidePassword2 === 'password'){
      setHidePassword2('text')
    }else{
      setHidePassword2('password')
    }
  }

  const handleOnCancel = () => {
    setActiveModal('')
    dispatch({
      type: UPDATE_REGISTRATION_STATUS
    })
  }

  const handleInput = (e) => {
    setRegistrationInfo(perState => ({
      ...perState,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    }))
  }

  const { userName, email, password, re_password, is_agreed } = registrationInfo

  const handleSubmint = (e) => {
    e.preventDefault()
    const formData = new FormData()
    const user_name = userName || 'NikolayR'; 
    formData.append('user_name', user_name);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('re_password', re_password);
    formData.append('is_agreed', is_agreed);
    dispatch(Registration(formData));
  }

  const goToTC = (e) => {
    e.preventDefault();
    setActiveModal('')
    history.push('/termsandconditions')
  }

  if (registrationSuccessStatus) {
    return <ModalMessage onClose={handleOnCancel}  header="Registration" isSuccess={true}>An account has been created and activation link has been sent to this email: "{registrationSuccessStatus.email}".</ModalMessage>
  }

  return (
    <MidiModal className="log-in-show" leftColumn={
      <div className="item-left">
        <h2>Welcome to <br/> Midistax!</h2>
        <p>Piano Midi Files for creatives</p>
        <a href="#0" onClick={e=>setActiveModal('login')}>Login</a>
      </div>
    } onClose={handleOnCancel}>
      <h3>Create Account</h3>
      <SocialLogin />
      <p>or use your email for registration</p>
      <form onSubmit={handleSubmint}>
          <div className={styles.registrationName}>
            <div className="mb-3 from-item">
                <label htmlFor="name" className="form-label"><img src="/img/user-person.png" alt="img" className="img-fluid" /></label>
                <input name="userName" value={userName} onChange={handleInput} className="form-control" id="userName" placeholder="Username" />
                <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.name}</span>
            </div>
          </div>
          <div className="mb-3 from-item">
              <label htmlFor="email" className="form-label"><img src="/img/mail.png" alt="img" className="img-fluid" /></label>
              <input name="email" value={email} onChange={handleInput} type="email" className="form-control" id="email" placeholder="Email" />
              <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.email}</span>
          </div>
          
          <div className="mb-3 from-item">
              <label htmlFor="Password" className="form-label"><img src="/img/lock.png" alt="img" className="img-fluid" /></label>
              <input name="password" value={password} onChange={handleInput} type={hidePassword1} className="form-control" id="Password" placeholder="Password" />
              <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.password}</span>
          </div>
          <div className="mb-3 from-item">
              <label htmlFor="Re-Password" className="form-label"><img src="/img/lock.png" alt="img" className="img-fluid" /></label>
              <input name="re_password" value={re_password} onChange={handleInput} type={hidePassword2} className="form-control" id="Re-Password" placeholder="Confirm Password" />
              <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.non_field_errors}</span>
          </div>
          <div className="mb-3 form-check text-start" style={{clear:'both'}}>
              <input type="checkbox" className="form-check-input" id="chkAgree" value={is_agreed} onChange={handleInput} name="is_agreed" />
              <label className="form-check-label" htmlFor="chkAgree">I Agree to <Link to="/termsandconditions" onClick={goToTC}>T &amp; C</Link></label>
              <span style={{ color: 'red', float: 'left' }}>{registrationFail && registrationFail.data && registrationFail.data.is_agreed}</span>
          </div>
          {registrationStart
            ? <MidiSpinner />
            : <button type="submit" className="btn btn-primary">Regsiter</button>
          }
      </form>
    </MidiModal>
  );
};

export default Registraion