import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useNavExpanded from '../../service/navExpandedService';

import { SET_MUSIC } from '../../store/action/ActionType'
import { getPlaylists } from '../../store/action/MusicAction';

import { SearchBar } from '../navbar/MidiNavbar';

function SideMenuLink({to, children}) {
  const location = useLocation();
  const { setNavExpanded } = useNavExpanded();
  return <Link to={to} className={location.pathname === to ? 'active' : ''} onClick={ee=>setNavExpanded()}>{children}</Link>
}

function PlaylistIcon({slug}) {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 21C17.5711 21 19.25 19.3211 19.25 17.25C19.25 15.1789 17.5711 13.5 15.5 13.5C13.4289 13.5 11.75 15.1789 11.75 17.25C11.75 19.3211 13.4289 21 15.5 21Z" stroke="url(#paint0_linear_2_382)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.25 17.25V1H24.25M14.25 2.25H1.75M1.75 7.25H14.25M9.25 12.25H1.75" stroke={`url(#pl_linear_${slug})`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
      <linearGradient id={`pl_linear_${slug}`} x1="1.75" y1="1.59741" x2="24.7043" y2="2.14338" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff"/>
        <stop offset="1" stopColor="#fff"/>
      </linearGradient>
      </defs>
    </svg>
  );
}

function MidiSidebar() {

  const location = useLocation();

  const {isPlaylistOffscreenOpen, playlists} = useSelector(state => state.music);
  const dispatch = useDispatch();

  const { isNavExpanded, setNavExpanded } = useNavExpanded();

  const handleClose = () => {
    dispatch({ type: SET_MUSIC, data: {isPlaylistOffscreenOpen: false} });
  }

  useEffect(() => {
    if (!playlists || !playlists.length) {
      dispatch(getPlaylists());
    }
  }, []);

  return (
    <div id="mySidenav" className="sidenav " style={{width:isNavExpanded?'100%':''}}>
      <button className="closebtn d-lg-none d-inline-block" onClick={ee=>setNavExpanded(false)}>&times;</button>
      <div className="left-item ">
          <div className="inner-left-item">
              <SearchBar className="d-lg-none d-block"/>
              <div className="item1">
                  <ul>
                      <li>
                          <SideMenuLink to="/">
                              <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.25 18.25H16.75M19.25 23.25H6.75C5.42392 23.25 4.15215 22.7232 3.21447 21.7855C2.27678 20.8479 1.75 19.5761 1.75 18.25V10.385C1.74998 9.52529 1.97165 8.68007 2.39359 7.93101C2.81552 7.18194 3.42348 6.55432 4.15875 6.10877L10.4088 2.32127C11.1901 1.84776 12.0863 1.59741 13 1.59741C13.9137 1.59741 14.8099 1.84776 15.5912 2.32127L21.8412 6.10877C22.5763 6.55421 23.1842 7.18163 23.6061 7.93046C24.028 8.67929 24.2498 9.52425 24.25 10.3838V18.25C24.25 19.5761 23.7232 20.8479 22.7855 21.7855C21.8479 22.7232 20.5761 23.25 19.25 23.25Z" stroke="url(#paint0_linear_2_380)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                  <defs>
                                  <linearGradient id="paint0_linear_2_380" x1="1.75" y1="1.59741" x2="24.7043" y2="2.14338" gradientUnits="userSpaceOnUse">
                                      <stop stopColor="#E649FF"/>
                                      <stop offset="1" stopColor="#A768FF"/>
                                  </linearGradient>
                                  </defs>
                              </svg>
                              Home
                          </SideMenuLink>
                      </li>
                      <li>
                          <SideMenuLink to="/midi-to-sheet-music">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M21.375 0.984375H2.625C2.18988 0.984375 1.77258 1.15723 1.4649 1.4649C1.15723 1.77258 0.984375 2.18988 0.984375 2.625V21.375C0.984375 21.8101 1.15723 22.2274 1.4649 22.5351C1.77258 22.8428 2.18988 23.0156 2.625 23.0156H21.375C21.8101 23.0156 22.2274 22.8428 22.5351 22.5351C22.8428 22.2274 23.0156 21.8101 23.0156 21.375V2.625C23.0156 2.18988 22.8428 1.77258 22.5351 1.4649C22.2274 1.15723 21.8101 0.984375 21.375 0.984375ZM6.14062 2.39062H9.42188V13.1719H6.14062V2.39062ZM10.125 14.5781C10.3115 14.5781 10.4903 14.504 10.6222 14.3722C10.754 14.2403 10.8281 14.0615 10.8281 13.875V2.39062H13.1719V13.875C13.1719 14.0615 13.246 14.2403 13.3778 14.3722C13.5097 14.504 13.6885 14.5781 13.875 14.5781H15.5156V21.6094H8.48438V14.5781H10.125ZM14.5781 13.1719V2.39062H17.8594V13.1719H14.5781ZM2.39062 21.375V2.625C2.39062 2.56284 2.41532 2.50323 2.45927 2.45927C2.50323 2.41532 2.56284 2.39062 2.625 2.39062H4.73438V13.875C4.73438 14.0615 4.80845 14.2403 4.94032 14.3722C5.07218 14.504 5.25102 14.5781 5.4375 14.5781H7.07812V21.6094H2.625C2.56284 21.6094 2.50323 21.5847 2.45927 21.5407C2.41532 21.4968 2.39062 21.4372 2.39062 21.375ZM21.6094 21.375C21.6094 21.4372 21.5847 21.4968 21.5407 21.5407C21.4968 21.5847 21.4372 21.6094 21.375 21.6094H16.9219V14.5781H18.5625C18.749 14.5781 18.9278 14.504 19.0597 14.3722C19.1915 14.2403 19.2656 14.0615 19.2656 13.875V2.39062H21.375C21.4372 2.39062 21.4968 2.41532 21.5407 2.45927C21.5847 2.50323 21.6094 2.56284 21.6094 2.625V21.375Z" fill="white" stroke="url(#paint0_linear_2_381)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                  <defs>
                                  <linearGradient id="paint0_linear_2_381" x1="1.75" y1="1.59741" x2="24.7043" y2="2.14338" gradientUnits="userSpaceOnUse">
                                      <stop stopColor="#fff"/>
                                      <stop offset="1" stopColor="#fff"/>
                                  </linearGradient>
                                  </defs>
                              </svg>
                              Midi to sheet music
                          </SideMenuLink>
                      </li>
                  </ul>
              </div>
              <div className="item1">
                  <h4>PLAYLISTS</h4>
                  <ul>
                    <li>
                      <SideMenuLink to="/songs/newest">
                        <PlaylistIcon slug="newest"/>
                        Newest Piano Midi
                      </SideMenuLink>
                    </li>

                    {playlists && playlists.map(plist => (
                      <li key={plist.slug}>
                        <SideMenuLink to={`/songs/${plist.slug}`}>
                          <PlaylistIcon slug={plist.slug}/>
                          {plist.name}
                        </SideMenuLink>
                      </li>
                    ))}
                      
                    <li>
                      <SideMenuLink to="/artists">
                        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.2426 17.484L18.1092 13.3505C18.8793 12.3231 19.3359 11.0479 19.3359 9.66797C19.3359 6.27557 16.576 3.51562 13.1836 3.51562C9.79119 3.51562 7.03125 6.27557 7.03125 9.66797C7.03125 13.0604 9.79119 15.8203 13.1836 15.8203C14.5635 15.8203 15.8387 15.3636 16.8662 14.5935L20.9996 18.7269C21.3428 19.0702 21.8994 19.0702 22.2426 18.7269C22.5858 18.3837 22.5858 17.8272 22.2426 17.484ZM8.78906 9.66797C8.78906 7.24482 10.7604 5.27344 13.1836 5.27344C15.6068 5.27344 17.5781 7.24482 17.5781 9.66797C17.5781 12.0911 15.6068 14.0625 13.1836 14.0625C10.7604 14.0625 8.78906 12.0911 8.78906 9.66797Z" fill="white" stroke="url(#paint0_linear_2_386)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M0.878906 1.75781H14.9414C15.4268 1.75781 15.8203 1.36433 15.8203 0.878906C15.8203 0.393486 15.4268 0 14.9414 0H0.878906C0.393486 0 0 0.393486 0 0.878906C0 1.36433 0.393486 1.75781 0.878906 1.75781Z" fill="white" stroke="url(#paint0_linear_2_386)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.12855 5.27344H0.878906C0.393486 5.27344 0 5.66692 0 6.15234C0 6.63776 0.393486 7.03125 0.878906 7.03125H5.12855C5.61397 7.03125 6.00745 6.63776 6.00745 6.15234C6.00745 5.66692 5.61397 5.27344 5.12855 5.27344Z" fill="white" stroke="url(#paint0_linear_2_386)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.57115 10.5469H0.878906C0.393486 10.5469 0 10.9404 0 11.4258C0 11.9112 0.393486 12.3047 0.878906 12.3047H4.57115C5.05657 12.3047 5.45005 11.9112 5.45005 11.4258C5.45005 10.9404 5.05652 10.5469 4.57115 10.5469Z" fill="white" stroke="url(#paint0_linear_2_386)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.91596 17.5781H0.878906C0.393486 17.5781 0 17.1846 0 16.6992C0 16.2138 0.393486 15.8203 0.878906 15.8203H7.91596C8.40137 15.8203 8.79486 16.2138 8.79486 16.6992C8.79486 17.1846 8.40137 17.5781 7.91596 17.5781Z" fill="white" stroke="url(#paint0_linear_2_386)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                            <defs>
                            <linearGradient id="paint0_linear_2_386" x1="1.75" y1="1.59741" x2="24.7043" y2="2.14338" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#fff"/>
                                <stop offset="1" stopColor="#fff"/>
                            </linearGradient>
                            </defs>
                        </svg>
                        Artists Index
                      </SideMenuLink>
                    </li>
                  </ul>
              </div>
              <div className="item1">
                  <ul>
                      <li><SideMenuLink to="/contactus">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M18.3336 0H3.66672C2.69424 0 1.7616 0.386314 1.07396 1.07396C0.386314 1.7616 0 2.69424 0 3.66672L0 14.6669C0 15.6393 0.386314 16.572 1.07396 17.2596C1.7616 17.9473 2.69424 18.3336 3.66672 18.3336H6.32509L10.4052 21.7831C10.5708 21.9231 10.7806 22 10.9974 22C11.2142 22 11.4241 21.9231 11.5896 21.7831L15.6752 18.3336H18.3336C19.3061 18.3336 20.2387 17.9473 20.9264 17.2596C21.614 16.572 22.0003 15.6393 22.0003 14.6669V3.66672C22.0003 2.69424 21.614 1.7616 20.9264 1.07396C20.2387 0.386314 19.3061 0 18.3336 0V0ZM20.167 14.6669C20.167 15.1531 19.9738 15.6194 19.63 15.9633C19.2861 16.3071 18.8198 16.5002 18.3336 16.5002H15.6752C15.2419 16.5004 14.8226 16.654 14.4918 16.9338L11.0002 19.8828L7.51036 16.9338C7.17903 16.6536 6.75904 16.4999 6.32509 16.5002H3.66672C3.18048 16.5002 2.71416 16.3071 2.37034 15.9633C2.02652 15.6194 1.83336 15.1531 1.83336 14.6669V3.66672C1.83336 3.18048 2.02652 2.71416 2.37034 2.37034C2.71416 2.02652 3.18048 1.83336 3.66672 1.83336H18.3336C18.8198 1.83336 19.2861 2.02652 19.63 2.37034C19.9738 2.71416 20.167 3.18048 20.167 3.66672V14.6669Z" fill="white" stroke="url(#paint0_linear_2_387)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M6.41668 6.41686H11.0001C11.2432 6.41686 11.4764 6.32028 11.6483 6.14837C11.8202 5.97646 11.9168 5.7433 11.9168 5.50018C11.9168 5.25706 11.8202 5.0239 11.6483 4.85199C11.4764 4.68007 11.2432 4.5835 11.0001 4.5835H6.41668C6.17356 4.5835 5.9404 4.68007 5.76849 4.85199C5.59658 5.0239 5.5 5.25706 5.5 5.50018C5.5 5.7433 5.59658 5.97646 5.76849 6.14837C5.9404 6.32028 6.17356 6.41686 6.41668 6.41686Z" fill="white" stroke="url(#paint0_linear_2_387)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M15.5835 8.25024H6.41668C6.17356 8.25024 5.9404 8.34682 5.76849 8.51873C5.59658 8.69064 5.5 8.9238 5.5 9.16692C5.5 9.41004 5.59658 9.6432 5.76849 9.81511C5.9404 9.98702 6.17356 10.0836 6.41668 10.0836H15.5835C15.8266 10.0836 16.0598 9.98702 16.2317 9.81511C16.4036 9.6432 16.5002 9.41004 16.5002 9.16692C16.5002 8.9238 16.4036 8.69064 16.2317 8.51873C16.0598 8.34682 15.8266 8.25024 15.5835 8.25024Z" fill="white" stroke="url(#paint0_linear_2_387)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M15.5835 11.9167H6.41668C6.17356 11.9167 5.9404 12.0133 5.76849 12.1852C5.59658 12.3572 5.5 12.5903 5.5 12.8334C5.5 13.0766 5.59658 13.3097 5.76849 13.4816C5.9404 13.6535 6.17356 13.7501 6.41668 13.7501H15.5835C15.8266 13.7501 16.0598 13.6535 16.2317 13.4816C16.4036 13.3097 16.5002 13.0766 16.5002 12.8334C16.5002 12.5903 16.4036 12.3572 16.2317 12.1852C16.0598 12.0133 15.8266 11.9167 15.5835 11.9167Z" fill="white" stroke="url(#paint0_linear_2_387)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                              <defs>
                              <linearGradient id="paint0_linear_2_387" x1="1.75" y1="1.59741" x2="24.7043" y2="2.14338" gradientUnits="userSpaceOnUse">
                                  <stop stopColor="#fff"/>
                                  <stop offset="1" stopColor="#fff"/>
                              </linearGradient>
                              </defs>
                          </svg>
                          Contact us
                      </SideMenuLink></li>
                      <li><SideMenuLink to="/about">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" fill="white" stroke="url(#paint0_linear_2_388)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9.82153 6.67857C9.82153 6.99115 9.9457 7.29092 10.1667 7.51195C10.3878 7.73297 10.6875 7.85714 11.0001 7.85714C11.3127 7.85714 11.6125 7.73297 11.8335 7.51195C12.0545 7.29092 12.1787 6.99115 12.1787 6.67857C12.1787 6.36599 12.0545 6.06622 11.8335 5.8452C11.6125 5.62417 11.3127 5.5 11.0001 5.5C10.6875 5.5 10.3878 5.62417 10.1667 5.8452C9.9457 6.06622 9.82153 6.36599 9.82153 6.67857ZM11.5894 9.42857H10.4108C10.3028 9.42857 10.2144 9.51696 10.2144 9.625V16.3036C10.2144 16.4116 10.3028 16.5 10.4108 16.5H11.5894C11.6974 16.5 11.7858 16.4116 11.7858 16.3036V9.625C11.7858 9.51696 11.6974 9.42857 11.5894 9.42857Z" fill="white" stroke="url(#paint0_linear_2_388)" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                              <defs>
                              <linearGradient id="paint0_linear_2_388" x1="1.75" y1="1.59741" x2="24.7043" y2="2.14338" gradientUnits="userSpaceOnUse">
                                  <stop stopColor="#fff"/>
                                  <stop offset="1" stopColor="#fff"/>
                              </linearGradient>
                              </defs>
                          </svg>
                          About
                      </SideMenuLink></li>
                      
                  </ul>
              </div>
              <div className="item2">
                  <Link to="/legal">Legal</Link>&nbsp;
                  <Link to="/termsandconditions">Terms and Conditions</Link>&nbsp;
                  <Link to="/privacypolicy">Privacy Policy</Link>
                  <p>© {new Date().getFullYear()} Copyright: <br/> Midistax - Midi files</p>
              </div>
          </div>
      </div>
    </div>
  )
}

export default MidiSidebar
