import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { AUTH_SUCCESS } from '../../store/action/ActionType';
import { onLogin } from '../../store/action/AuthAction';
import { CartItems, CountCartedMusic } from '../../store/action/MusicAction';
import { getUserInfo } from '../../store/action/UserAction';

async function queryAuthUrl(provider) {
  try {
      const config = {
        params: {
          redirect_uri: window.location.origin + '/accounts/google/login/callback/'
        },
        withCredentials: true,
      }
      const ret = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/o/${provider}/`, config);
      const auth_url = ret.data.authorization_url;
      return auth_url;
  } catch(err) {
      const error =  err.response ? err.response  : "networkError"
      console.log(err);
      return '';
  }
}

const provider = 'google-oauth2';

async function postCode(provider, state, code) {
  try {
      const formData = new URLSearchParams()
      formData.append('state', state)
      formData.append('code', code)

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      };

      const ret = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/o/${provider}/`, formData, config);
      return ret.data;
  } catch(err) {
      const error =  err.response ? err.response.data  : "networkError"
      console.log(err);
      return { error };
  }
}

const popup = (url) => {
  const windowArea = {
    width: Math.floor(window.outerWidth * 0.8),
    height: Math.floor(window.outerHeight * 0.5),
  };

  if (windowArea.width < 1000) { windowArea.width = 1000; }
  if (windowArea.height < 630) { windowArea.height = 630; }
  windowArea.left = Math.floor(window.screenX + ((window.outerWidth - windowArea.width) / 2));
  windowArea.top = Math.floor(window.screenY + ((window.outerHeight - windowArea.height) / 8));

  const sep = (url.indexOf('?') !== -1) ? '&' : '?';
  url = `${url}${sep}`;
  const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
    width=${windowArea.width},height=${windowArea.height},
    left=${windowArea.left},top=${windowArea.top}`;

  const authWindow = window.open(url, 'producthuntPopup', windowOpts);
  // Create IE + others compatible event handler
  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

  // Listen to message from child window
  const authPromise = new Promise((resolve, reject) => {
    eventer(messageEvent, (e) => {
      if (e.origin !== window.location.origin) {
        return;
      }

      if (e.data.error) {
        reject('Unauthorised');
      } else {
        resolve(e.data);
      }

      authWindow.close();

    }, false);
  });

  return authPromise;
};


function SocialLogin() {

  const dispatch = useDispatch();

  const handleLogin = async (provider) => {
    const authUrl = await queryAuthUrl(provider);
    const { state, code } = await popup(authUrl);
    
    const data = await postCode(provider, state, code);
    
    if (data.access) {
      onLogin(dispatch, { ...data, emial: data.user, is_superuser: false });
    } else {

      //TODO: Show error snack bar

    }
  }

  return (
    <div style={{marginLeft:-5, marginRight:-5}}>
      <GoogleLoginButton onClick={e=>handleLogin('google-oauth2')} size={36} />
      {/* <FacebookLoginButton onClick={() => alert("Hello")} size={36} /> */}
    </div>
  )
}

export default SocialLogin