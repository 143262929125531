import React, {useState} from 'react'
import {ResetPasswordConfirms} from '../store/action/AuthAction'
import { useDispatch, useSelector } from 'react-redux'
import {useHistory, useParams } from "react-router-dom";
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import styles from './profile/Profile.module.scss'
import MidiSpinner from '../component/midiSpinner/MidiSpinner';
import useActiveModal from '../service/activeModalService';

function ResetPasswordConfirm() {

    const dispatch = useDispatch()
    const history = useHistory()

    const { setActiveModal } = useActiveModal();

    const { token } = useParams()

    const { accessToken, startForgetPassword, forgetPasswordSuccess, forgetPasswordFail } = useSelector(state => state.auth)
    const [restPasswordInfo, setRestPasswordInfo] = useState({
        password:'',
        re_password:''
    })

    const {password, re_password} = restPasswordInfo

    const handleInput = (e) =>{

        setRestPasswordInfo(prevState=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))

    }

    const handleSubmit = () =>{
        const formData = new FormData()
        formData.append('token', token)
        formData.append('password', password)
        dispatch(ResetPasswordConfirms(formData))
    }

    if (accessToken) {
      history.push('/');
      return (<></>)
    }

    return (
      <div className="Profile">
        <h2>Reset Password</h2>

        {forgetPasswordSuccess
          ? 
          <>
            <p style={{color:'white', textAlign:'center', paddingTop: '1.5rem'}}>Password Reset Successfully, try to login again</p>
            <div className="item">
              <div className="item-link" style={{textAlign: 'center'}}>
                <a href="#0" onClick={ee=>ee.preventDefault()||setActiveModal('login')}>Login</a>
                <span></span>
              </div>
            </div>
          </>
          : 
          <>
            <div className={`item ${styles.profileForm}`}>
              <Row>
                <Col md={6}>
                  <Form.Label>New Password</Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control name="password" value={password} onChange={handleInput}  className="login__input" type="password" placeholder="New Password" id="password" />
                    <span style={{color:'red', float:'left'}}>{forgetPasswordFail && forgetPasswordFail.data && forgetPasswordFail.data.password}</span>
                    <span style={{color:'red', float:'left'}}>{forgetPasswordFail && forgetPasswordFail.data && forgetPasswordFail.data.detail}</span>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {startForgetPassword
              ?
              <MidiSpinner />
              :
              <div className="item-link">
                <a href="#0" onClick={ee=>ee.preventDefault()||handleSubmit()}>Save New Password</a>
                <span></span>
              </div>
            }
          </>
        }
      </div>
    )
}

export default ResetPasswordConfirm
