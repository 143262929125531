import React from 'react'
import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap'

import styles from './SongSearchItem.module.scss'

const popover = (
  <Popover className="order-popover">
    <Popover.Header as="h3">Request, or Order, that is the question</Popover.Header>
    <Popover.Body>
      <p>You can request this song to let us know that you are interested to buy it. We will send you a notification when it's available for purchase.</p>
      <p>Want it sooner? <strong>Order</strong> this song at <strong>$5.99</strong>, we will fulfill your order <strong>in 7 business days</strong>.</p>
    </Popover.Body>
  </Popover>
);

const orderOnlyPopover = (
  <Popover className="order-popover">
    <Popover.Header as="h3" style={{color:'black'}}>Order the song</Popover.Header>
    <Popover.Body>
      This song is already requested.
      You still can <strong>Order</strong> it at <strong>$5.99</strong> to get it sooner, we will fulfill your order <strong>in 7 business days</strong>.
    </Popover.Body>
  </Popover>
);

const requestOnlyPopover = (
  <Popover className="order-popover">
    <Popover.Header as="h3" style={{color:'black'}}>Request &amp; Add to Watchlist</Popover.Header>
    <Popover.Body>
      <p>You can request this song to let us know that you are interested to buy it. We will send you a notification when it's available for purchase.</p>
    </Popover.Body>
  </Popover>
);

const CustomOrderToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button onClick={onClick} style={{ marginTop:0}} className="btn btn-add-to-cart dropdown-toggle" title="Send request">{children}</button>
));

const InfoIcon = ({overlay}) => (
  <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={overlay}>
    <span className="info-icon" style={{display:'inline-block', color: 'black', fontSize: 18, lineHeight: 1.0, verticalAlign: 'middle'}}><i className="fas fa-info-circle"></i></span>
  </OverlayTrigger>
)


function SongSearchItemInfo({music, buttons}) {
  return (
    <div className={`col-sm-${buttons?12:9}`}>
      <div className="media">
          <span className="d-block" style={{marginRight: 20}}>
              <img style={{maxWidth: 103, borderRadius: 10}} src={music.artwork_small} className="img-fluid align-self-center" alt="..."/>
          </span>
          <div className="media-body">
              <h5 className="mt-0">{music.artist}</h5>
              <h4>{music.title}</h4>
              {buttons}
          </div>
      </div>
    </div>
  );
}


function SongSearchItem({isFirst, music, addToCart, requestSong, status}) {

  const isOrdered = status === 'ordered';
  const isRequested = status === 'requested';
  const hasOrderOption = false; //!isOrdered;
  const hasRequestOption = !isOrdered && !isRequested;

  const buttons = (
    <div className="download text-end">
        {isOrdered && <button style={{ marginTop:0}} className="btn btn-add-to-cart" title="">Ordered</button>}
        {!isOrdered && !hasOrderOption && isRequested && <button style={{ marginTop:0}} className="btn btn-add-to-cart" title="">Requested</button>}
        {hasOrderOption && hasRequestOption &&
        <Dropdown>
          <Dropdown.Toggle as={CustomOrderToggle} variant="info">
            Order
          </Dropdown.Toggle>
          <InfoIcon overlay={popover} />
          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={e => requestSong && requestSong(music)}>Request &amp; Add to Watchlist</Dropdown.Item>
            <Dropdown.Item onClick={e => addToCart && addToCart(music)}>Order at $5.99 <span className={styles.days}>(in 7 days)</span></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        }
        {!hasOrderOption && hasRequestOption && 
        <>
          <button onClick={e => requestSong && requestSong(music)} style={{ marginTop:0}} className="btn btn-add-to-cart" title="Send request">Request</button>
          <InfoIcon overlay={requestOnlyPopover}/>
        </>
        }
        {hasOrderOption && !hasRequestOption && 
        <>
          <button onClick={e => addToCart && addToCart(music)} style={{ marginTop:0}} className="btn btn-add-to-cart" title="Send request">$5.99 <span className={styles.days}>(in 7 days)</span></button>
          <InfoIcon overlay={orderOnlyPopover}/>
        </>
        }
    </div>
  );
  
  return (
    <div className={`${isFirst?'item':'item mt-3'} ${styles.searchitem}`}>
      <div className="row g-0">
        
        <SongSearchItemInfo music={music} buttons={buttons} />

      </div>
    </div>
  )
}

export default SongSearchItem;
export { SongSearchItemInfo };
