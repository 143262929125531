import { AUTH_LOGOUT } from "./store/action/ActionType";
import { refresh } from "./store/action/AuthAction";

function mediaURL(path) {
  return `${process.env.REACT_APP_MEDIA_BASE}${path}`
}

function _getToken(tokenName, checkExpiration) {
  if (checkExpiration) {
    const exp_at = localStorage.getItem(tokenName+'_exp')
    if (!exp_at || new Date().getTime() > +exp_at) {
      return ''
    }
  }
  return localStorage.getItem(tokenName)
}

function getAccessToken(checkExpiration = false) {
  return _getToken('access_token', checkExpiration);
}

function updateTokenChecker(dispatch, isOnLogout, isReloadAfterRefresh = false) {
  clearTimeout(window._check_token_task);
  if (isOnLogout) {
    localStorage.clear();
    dispatch({
        type:AUTH_LOGOUT
    })
    return;
  }
  
  if (!getAccessToken()) {
    // No access token, even expired one, nothing to refresh
    return;
  }

  const exp_at = localStorage.getItem('access_token_exp')
  const now = new Date().getTime()
  const exp_in = +exp_at - now;
  if (exp_in > 100) {
    window._check_token_task = setTimeout(() => updateTokenChecker(dispatch, false), exp_in);
    return;
  }

  const refreshToken = _getToken('refresh_token', true);
  if (!refreshToken) {
    localStorage.setItem('access_token', '');
    localStorage.setItem('access_token_exp', '');
    localStorage.setItem('refresh_token', '');
    localStorage.setItem('refresh_token_exp', '');
    document.location.reload();
    return;
  }

  const formData = new FormData()
  formData.append('refresh', refreshToken)
  dispatch(refresh(formData, isReloadAfterRefresh))

  // schedule additional check to handle possible refresh failure
  window._check_token_task = setTimeout(() => updateTokenChecker(dispatch, false), 30000);
}

export { mediaURL, getAccessToken, updateTokenChecker };