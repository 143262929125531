import { useDispatch, useSelector } from "react-redux";
import { SET_MUSIC } from '../store/action/ActionType'

function useActiveModal() {

  const { activeModal, activeModalCtx } = useSelector(state => state.music)
  const dispatch = useDispatch();

  const setActiveModal = (modal, ctx) => {
    dispatch({type: SET_MUSIC, data: {activeModal: modal, activeModalCtx: ctx }});
  }

  return { activeModal, setActiveModal, activeModalCtx }
}

export default useActiveModal;