import React, {useEffect, useState} from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {Link, NavLink, useParams} from 'react-router-dom'
import {getSingeleSong} from '../../store/action/MusicAction'
import { Helmet } from "react-helmet";
import { mediaURL } from '../../utils'
import SongItem from '../../component/songItem/SongItem';

import { useCart } from '../../service/cartService';
import { Col, Row } from 'react-bootstrap';
import { useDownloadMidi } from '../../service/downloadMidiService';

import youtubeStyles from '../../component/youtubePreview/YoutubePreview.module.scss'
import { useActiveMusic } from '../../service/activeMusicService';
import useActiveModal from '../../service/activeModalService';
import Download from '../modals/Download';


function SingleSongView() {

  const dispatch = useDispatch()
  const { getSingleSongSuccess } = useSelector(state => state.music)
  const {tid} = useParams()
  
  const id = tid.split('-').pop();

  const [pageData, setPageData] = useState(null);
  
  const { addToCart } = useCart();

  const { downloadSegment } = useDownloadMidi();

  const { activeModal } = useActiveModal();

  useEffect(() => {
    if (getSingleSongSuccess && getSingleSongSuccess.singleSong.id == id) {
      setPageData(getSingleSongSuccess);
    } else {
      setPageData(null);
      dispatch(getSingeleSong(id));
    }
  }, [id, getSingleSongSuccess && getSingleSongSuccess.singleSong.id])

  const { activeMusicId, setActiveMusic } = useActiveMusic();

  const getMainArtistName = (music) => {
    return music.artists[0].name;
  }

  const getArtistUrl = (music) => {
    const artist = music.artists[0].slug;
    return `/${artist}`;
  }

  const getTitle = (music) => {
    return `${getMainArtistName(music)} - ${music.title}`;
  }

  const getDescription = (music) => {
    return `${getMainArtistName(music)} - ${music.title}, Song key - ${music.key}, BPM - ${music.bpm}, File Format : MIDI file. Download and use in all midi supported applications: Synthesia, DAW Software, FL Studio, Logic Pro, Ableton & more. Includes Melody and Chords.`;
  }

  const getCreativeWorkTitle = (music) => {
    return music.artists.map(artist => artist.name).join(', ') + ' - ' + music.title + '.mid';
  }

  return (
    <div className="Genre">
      <Helmet>
        <title>{pageData && `${getTitle(pageData.singleSong)} (Piano Midi Download)`}</title>
        <meta name="description" content={pageData && getDescription(pageData.singleSong)} />
        {pageData && pageData.singleSong && <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "CreativeWork",
          "headline": "${getCreativeWorkTitle(pageData.singleSong)}",
          "description": "Free 10 seconds sample midi file",
          "isAccessibleForFree": false,
          "hasPart":
            {
            "@type": "WebPageElement",
            "isAccessibleForFree": false,
            "cssSelector" : ".paywall"
            }
        }`}
        </script>}
      </Helmet>                 
                        
      <div className="Genre-inner">
        <div className="row ">
                
          <div className="col-lg-6">
              <div className="video_play">
                  <div style={{textAlign:'center'}}>
                      <div className={youtubeStyles.responsiveYoutube}>
                        <iframe style={{minWidth:'360px', minHeight:'200px'}} src={`https://www.youtube.com/embed/${pageData && pageData.singleSong.youtubeId}?&frameborder=0`} frameBorder="0" allowFullScreen></iframe>
                      </div>
                      <div className="item-body text-start" style={{marginTop: 7}}>
                        {pageData && pageData.singleSong && <SongItem music={pageData.singleSong} type="single"  isActive={activeMusicId===pageData.singleSong.id} setActiveMusic={setActiveMusic} addToCart={addToCart} download={(music)=>downloadSegment(music, false, true)}/>}
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-lg-6">
            {pageData && pageData.similarSong && pageData.similarSong.length>0 &&
            <div className="item-body">
              <h4>More songs by <Link to={getArtistUrl(pageData.singleSong)}>{getMainArtistName(pageData.singleSong)}</Link></h4>
              {pageData && pageData.similarSong && pageData.similarSong.map((music, index) =>(
                <SongItem key={index} isFirst={index<1} isActive={activeMusicId===music.id} setActiveMusic={setActiveMusic} music={music} type="similar" addToCart={addToCart} download={downloadSegment} />
              ))}
            </div>}
          </div>
        </div>
      </div>

      <div style={{display:activeModal==='download-main'?'':'none'}} className="paywall">
        {pageData && pageData.singleSong && <Download mainMusic={pageData.singleSong} />}
      </div>
    </div>
  )
}

export default SingleSongView








