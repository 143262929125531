import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../store/action/AuthAction'
import { UPDATE_AUTH_STATUS, UPDATE_FORGET_PASSWORD_REQUEST_STATUS } from '../../store/action/ActionType'

import MidiModal from './MidiModal';
import SocialLogin from '../socialLogin/SocialLogin';

import MidiSpinner from '../../component/midiSpinner/MidiSpinner';


const Login = ({ setActiveModal }) => {

  const dispatch = useDispatch()
  const [hidePassword, setHidePassword] = useState('password')

  const { authStart, accessToken, authFail } = useSelector(state => state.auth)

  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: ''
  })
  const { email, password } = loginInfo

  const handleShowPassword = () =>{
    if (hidePassword === 'password'){
      setHidePassword('text')
    }else{
      setHidePassword('password')
    }
  }
  const hangleOnClancel = () => {
    setActiveModal('')
    dispatch({ type: UPDATE_AUTH_STATUS })
    dispatch({type:UPDATE_FORGET_PASSWORD_REQUEST_STATUS})
  }

  const handleInput = (e) => {
    setLoginInfo(perState => ({
      ...perState,
      [e.target.name]: e.target.value

    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('email', email)
    formData.append('password', password)
    dispatch(login(formData))
  }

  return (
    <MidiModal className="sign-in-show" leftColumn={
      <div className="item-left">
        <h2>Welcome to <br /> Midistax!</h2>
        <p>Piano Midi Files for creatives</p>
        <a href="#0" onClick={e=>setActiveModal('registration')}>Register</a>
      </div>
    } onClose={hangleOnClancel}>
      <h3>Sign in</h3>
      <SocialLogin />
      <p>or use your email to login</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3 from-item">
          <label htmlFor="email" className="form-label"><img src="/img/mail.png" alt="img" className="img-fluid" /></label>
          <input onChange={handleInput} value={email} name="email" type="email" className="form-control" id="email" placeholder="Email" required />
          <span style={{ color: 'red', fontSize: 11, float: 'left' }}>{authFail && authFail.data && authFail.data.email && authFail.data.email}</span>
        </div>
        
        <div className="mb-3 from-item">
          <label htmlFor="Password" className="form-label"><img src="/img/lock.png" alt="img" className="img-fluid" /></label>
          <input onChange={handleInput} value={password} name="password" type={hidePassword} className="form-control" id="Password" placeholder="Password" />
          <span style={{ color: 'red', fontSize: 11, float: 'left' }}>{authFail && authFail.data && authFail.data.detail}</span>
          <span style={{ color: 'red', fontSize: 11, float: 'left' }}>{authFail && authFail.data && authFail.data.password && authFail.data.password}</span>
        </div>

        {authStart
          ? <MidiSpinner />
          : <button onClick={handleSubmit} type="submit" className="btn btn-primary">Login</button>
        }

        <div style={{textAlign: 'right', position: 'relative', top: 5, marginTop: 10}}>
          <a href="#0" onClick={e=>e.preventDefault()||setActiveModal('password')} style={{color: 'grey'}}>Forget password?</a>
        </div>
      </form>
    </MidiModal>
  );
};

export default Login