import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from "react-helmet";
import Playlist from '../component/playlist/Playlist';
import { Link, useHistory, useParams } from 'react-router-dom';
import ModalMessage from './modals/ModalMessage';
import { Button, Spinner } from 'react-bootstrap';
import { SET_MUSIC } from '../store/action/ActionType';
import { useDispatch } from 'react-redux';
import MidiBanner from '../component/midiBanner/MidiBanner';
import MidiSpinner from '../component/midiSpinner/MidiSpinner';


async function activate(uid, code) {
  const formData = new URLSearchParams();
  formData.append('uid', uid)
  formData.append('token', code)
  try {  
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      //withCredentials: true,
    };

    const ret = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/activation/`, formData, config);
    debugger;
    return [true, null];
  } catch (err) {
    const error =  err.response ? err.response  : "networkError"
    return [false, error];
  }
}


function HomePage() {
    const [adds, setAdds]=useState()

    const { uid, code } = useParams();
    const [ activatingStatus, setActivatingStatus ] = useState('activating');
    const history = useHistory();

    const dispatch = useDispatch();

    const closeActivating = () => {
      history.push('/');
    };

    useEffect( async () => {
        try {  
            await axios.get(process.env.REACT_APP_BACKEND_URL+"/music/listofadd/").then((res) => {
              setAdds(res.data)
            })
        } catch (err) {
          const error =  err.response ? err.response  : "networkError"
        }
    },[]);

    useEffect( async () => {
      if (uid && code) {
        setActivatingStatus('activating');
        const [is_ok, error] = await activate(uid, code);
        if (is_ok) {
          setActivatingStatus('activated');  
        } else if (error.detail === 'Stale token for given user.') {
          setActivatingStatus('expired');
        } else {
          setActivatingStatus('error');
        }
      }
    },[uid, code]);

    const setActiveModal = (modal) => {
      dispatch({type: SET_MUSIC, data: {activeModal: modal}})
    }

    const handleLogin = () => {
      closeActivating();
      setActiveModal('login');
    }

    return (
      <>
        {uid && code && 
        <ModalMessage header="Account Activation" onClose={closeActivating} isSuccess={activatingStatus === 'activated'}>
            {activatingStatus === 'activating' && <MidiSpinner />}
            {activatingStatus === 'activated' && <>Your account has been activated and is ready to use! Please <a href="#" onClick={handleLogin}>Login</a>.</>}
            {activatingStatus === 'expired' && <>Link is used or expired! Try to register, login or reset password.</>}
            {activatingStatus === 'error' && <>Unexpected error, please try to refresh the page!</>}

          {activatingStatus !== 'activating' &&
          <div style={{textAlign: 'center', paddingTop: 20}}>
            <Button variant="primary" onClick={activatingStatus==='activated'?handleLogin:closeActivating}>{activatingStatus === 'activated'?'OK':'Close'}</Button>
          </div>
          }
        </ModalMessage>
        }
        <Helmet></Helmet>

        <MidiBanner />

        <div className="Genre">
          <div className="Genre-inner">
            <div className="row ">
              <div className="col-lg-6"><Playlist infinite={false} slug="top" ads={adds && adds.add2} /></div>
              <div className="col-lg-6"><Playlist infinite={false} slug="newest" ads={adds && adds.add1} /></div>
            </div>
          </div>
        </div>      
      </>
    )
}

export default HomePage
