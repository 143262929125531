import React, { lazy, useEffect} from 'react'
import Cart  from './container/cart/Cart'
import HomePage from './container/HomePage'

import ResetPasswordConfirm from './container/ResetPasswordConfirm'
import Profile from './container/profile/Profile'
import SearchSong from './container/searchSong/SearchSong'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {useDispatch} from 'react-redux'
import  {getUserInfo}  from './store/action/UserAction'
import {AuthenticRoute, IsAdminRoute} from './PrivateRoute'
import SingleSongView from './container/singleSonView/SingleSongView'
import TermsAndCondition from './component/termsAndCondition/TermsAndCondition'
import ScrollToTop from './ScrollToTop'
import { Helmet } from "react-helmet";
import Layout from './container/layout/Layout'
import Playlist from './component/playlist/Playlist'
import OAuth2Redirect from './container/OAuth2Redirect'
import Artist from './container/artist/Artist'
import ArtistIndex from './container/artist/ArtistIndex'
import DownloadMusic from './container/downloadMusic/DownloadMusic'
import Midi2SheetMusic from './container/midi2SheetMusic/Midi2SheetMusic'
import { getAccessToken, updateTokenChecker } from './utils'
import AboutPage from './page/AboutPage/AboutPage'

const Admin = lazy(() => import('./container/admin/Admin'));
const MusicList = lazy(() => import('./container/MusicList'));
const UpdateMusic = lazy(() => import('./container/updateMusic/UpdateMusic'));
const UploadMusicMenually = lazy(() => import('./container/uploadMusicMenually/UploadMusicMenually'));


function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const curToken = getAccessToken(true);
    
    if (curToken) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAccessToken(),
        },
      }
      dispatch(getUserInfo(config))
    }

    updateTokenChecker(dispatch, false, !curToken);
    
  }, [])


  return (
    <div className="App">
      <Helmet>
        <title>{'Midistax - Download Synthesia Piano Midi Files: Melody, Chords & Song Key'}</title>
        <meta name="description" content={'Explore Midistax Piano Midi Files Database. Perfect for music learning, sheet music & music production. Download and use in all midi supported applications: Synthesia, DAW Software, FL Studio, Logic Pro, Ableton & more. Includes Melody, Chords, Song Key & BPM.'} />
      </Helmet>
      <Router>
        <ScrollToTop />
        <Switch>
          <IsAdminRoute exact path="/uploadsong" component={UploadMusicMenually} /> 
          <IsAdminRoute exact path='/manage-midistax-front' component={Admin} /> 
          <IsAdminRoute exact path='/musiclist' component={MusicList} /> 
          <IsAdminRoute exact path='/updatemusic/:songId' component={UpdateMusic} /> 
          
          {/* <Route exact path='/activate/:uid/:token' component={EmailVarification} /> */}

          <Route exact path='/midi-to-sheet-music'><Layout showSynthesia={true}><Midi2SheetMusic/></Layout></Route>
          
          <Route exact path='/contactus'><Layout><TermsAndCondition page="contactUs" /></Layout></Route>

          <Route exact path='/'><Layout><HomePage /></Layout></Route>
          <Route exact path='/activate/:uid/:code'><Layout><HomePage /></Layout></Route>

          <Route exact path='/accounts/google/login/callback/'><OAuth2Redirect /></Route>

          <Route exact path='/songs/:slug'><Layout showSynthesia={true}><Playlist infinite={true} /></Layout></Route>
          <Route exact path='/cart'><Layout showSynthesia={true}><Cart /></Layout></Route>
          <Route exact path='/search/:song'><Layout showSynthesia={false}><SearchSong /></Layout></Route>

          <AuthenticRoute exact path='/profile'><Layout showSynthesia={true}><Profile /></Layout></AuthenticRoute>
          <AuthenticRoute exact path='/download'><Layout showSynthesia={false}><DownloadMusic /></Layout></AuthenticRoute>

          <Route exact path='/about'><Layout showSynthesia={false} styles={{wrapper:{background: ''}}}><AboutPage /></Layout></Route>
          <Route exact path='/legal'><Layout showSynthesia={true}><TermsAndCondition page="legal" /></Layout></Route>
          <Route exact path='/termsandconditions'><Layout showSynthesia={true}><TermsAndCondition page="termsAndCondition" /></Layout></Route>
          <Route exact path='/privacypolicy'><Layout showSynthesia={true}><TermsAndCondition page="privacyPolicy" /></Layout></Route>

          <Route exact path='/password/reset/confirm/:token'><Layout showSynthesia={true}><ResetPasswordConfirm /></Layout></Route>

          <Route exact path='/artists'><Layout><ArtistIndex /></Layout></Route>

          <Route exact path='/tag-:slug'><Layout showSynthesia={true}><Playlist infinite={true} tagmode={true}/></Layout></Route>
          <Route exact path='/genre-:slug'><Layout showSynthesia={true}><Playlist infinite={true} tagmode={true}/></Layout></Route>

          <Route exact path="/:artistSlug/:songSlug-:tid"><Layout><SingleSongView /></Layout></Route>
          <Route exact path='/:artistSlug'><Layout showSynthesia={false}><Artist /></Layout></Route>
          
        </Switch>
      </Router>
    </div>
  );
}

export default App;
