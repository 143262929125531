import React from 'react'
import MidiNavbar from '../../component/navbar/MidiNavbar'
import MidiSidebar from '../../component/sidebar/MidiSidebar';

import YoutubePreview from '../../component/youtubePreview/YoutubePreview';
import MobileFooter from '../../component/footer/MobileFooter';


const Synthesia = ({isLeft, isFirst, isRight}) => {
  isFirst = isFirst !== false;
  return (
    <div className={'col-lg-6'+(isLeft?' d-lg-none d-block':'')+(isRight?' d-lg-block d-none':'')}>
      <div className="video_play">
        <div className={'box-item text-start'+(isFirst?' mt-0':'')}>
          <img src="/img/start.svg" alt="img" className="img-fluid"/>
          <h4>Piano Midi songs for SYNTHESIA</h4>
          <p>Buying expensive Midi files is now a thing of the past. Our midi service is affordable and a growing new database of Midi files in piano format.</p>
          <p>All of our midi songs can be used in Synthesia! All our midi songs can converted to sheet music using a free download app www.musescore.org</p>
        </div>
      </div>
    </div>
  )
}

function Layout({children, showSynthesia, styles={}}) {
  const wrapperStyle = (styles && styles.wrapper) || {};
  return ( 
    <>
      <MidiNavbar />
      <div className="wrapper" style={wrapperStyle}>
        <div className="container-fluid">
          <MidiSidebar />
          <div className="mide-item">
            {!showSynthesia && children}
            {showSynthesia && 
              <div className="Genre">
                <div className="Genre-inner">
                  <div className="row ">
                    <Synthesia isLeft={true} />
                    <div className="col-lg-6">
                      {children}
                    </div>
                    <Synthesia isRight={true} />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <MobileFooter />
      </div>
      <YoutubePreview />
    </>
  )
}

export default Layout
export { Synthesia }
