import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import {getExtraInfo} from '../../store/action/AdminAction'

import styles from './TermsAndCondition.module.scss'


function TermsAndCondition({ page }) {

    const dispatch = useDispatch()
    const { getExtraInfosuccess } = useSelector(state => state.admin)
    
    useEffect(() => {
        dispatch(getExtraInfo())
    }, []);

    const titles = {
      contactUs: 'Contact Us',
      aboutUs: 'About Me',
      legal: 'Legal',
      termsAndCondition: 'Terms and Conditions',
      privacyPolicy: 'Privacy Policy',
    }

    return (
      <div className={styles.main}>
        <Helmet>
          <title>Midistax: {titles[page]}</title>
        </Helmet>
        <section className={styles.mainSection}>
          <article className="page type-page status-publish hentry">
            <div className="entry">
              <div dangerouslySetInnerHTML={{__html: getExtraInfosuccess && getExtraInfosuccess[page]}} />        
            </div>
          </article>
        </section>
      </div>
    )
}

export default TermsAndCondition
