import React from 'react';
import MidiModal from './MidiModal';

import styles from './Modals.module.scss'

const ModalMessage = ({ header, children, onClose, isSuccess }) => {

  return (
    <MidiModal onClose={onClose} className={styles.modalMessage}>
      {header && <h3>{header}</h3>}
      <div style={{padding:'30px 50px 40px', fontSize: 24, color: isSuccess?'#000000':'brown'}}>
        {children}
      </div>
    </MidiModal>
  );
};

export default ModalMessage