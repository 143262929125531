import axios from 'axios'
import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CartItems , purchMusic, RemoveMusicFromCart} from '../../store/action/MusicAction'
import { useHistory } from 'react-router-dom'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { SET_MUSIC } from '../../store/action/ActionType'

import { useActiveMusic } from '../../service/activeMusicService';

import styles from './Cart.module.scss';
import { getAccessToken } from '../../utils'
import { Popover } from 'react-bootstrap'
import { getLocalCart, removeFromLocalCart } from '../../service/cartService'


import SocialLogin from '../socialLogin/SocialLogin'

import { SongItemInfo } from '../../component/songItem/SongItem';
import { SongSearchItemInfo } from '../../component/songSearchItem/SongSearchItem'


function CartAmount({data}) {

  if (!data || data.music.length+data.orders.length<1) {
    return <h6>Total Amount: <span className="d-inline-block">$</span></h6>
  }

  const creditsBalancePopover = (
    <Popover>
      {/* <Popover.Header as="h3">Request, or Order, that is the question</Popover.Header> */}
      <Popover.Body>
        {data.credits_before>0 &&
        <p>
          You have <strong>{data.credits_before}</strong> credits on your balance.
        </p>
        }
        {data.use_credits>0 &&
        <p>
          We will use
          {data.credits_after>0 && <><strong> {data.use_credits}</strong> credits </>}
          {!data.credits_after && ` it `}
          to decrease your payment. <strong>{data.credits_after}</strong> credits will remain after this purchase.
        </p>
        }
        {data.bonus_credits>0 && 
        <p>
          You will receive <strong>{data.bonus_credits}</strong> credits bonus later (in about 2 days) after order fulfillment. One credit is one free midi, but it expires in 31 days.
        </p>
        }
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      {/* <ul>
        <li>Bonus Credits:</li>
        <li>2 <img src="img/ant-design_info-circle-outlined.png" alt="img" className="img-fluid"/></li>
      </ul> */}
      <h6>Total Amount: <span className="d-inline-block">${data.total.toFixed(2)}</span></h6>
    </>
  )
}

function CartItem({ className, music, isActive, removeItemFormCart, setActiveMusic }) {
  return (
    <div className={className?`item ${className}`:'item'}>
      <div className="row g-0">
          
        {music.slug
          ? <SongItemInfo music={music} isActive={isActive} setActiveMusic={setActiveMusic} isShowBadges={false}/>
          : <SongSearchItemInfo music={music} />
        }

        <div className="col-sm-3 align-self-center">
            <div className="price-text text-end">
                <ul>
                    <li>${music.price}</li>
                    <li><button onClick={e =>{removeItemFormCart(music)}}><i className="fas fa-times"></i></button></li>
                </ul>
            </div>
        </div>
          
      </div>
    </div>
  )
}


function Cart() {

    const history = useHistory()
    const dispatch = useDispatch()
    const { getAddToCartItemSuccess } = useSelector(state => state.music)
    const { accessToken} = useSelector(state => state.auth)
    const [showPaypal, setShowPaypal] = useState(false)

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAccessToken(),
      },
    };

    const guestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    useEffect(() => {
        if (accessToken) {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getAccessToken(),
            },
          };
          dispatch(CartItems(config))
        } else {
          const cartData = getLocalCart();
          dispatch(CartItems(guestConfig, cartData))
        }
    }, [accessToken])

    const PurchaseMusic = async () =>{
      const stub = false;
      if (stub) {
        const id = await createOrder(null ,null);
        onApprove({ orderID: id }, null);
      } else {
        setShowPaypal(!showPaypal)
      }
    }

    const removeItemFormCart = (music) =>{
      if (accessToken) {
        dispatch(RemoveMusicFromCart(music, config))
      } else {
        removeFromLocalCart(dispatch, music);
        const cartData = getLocalCart();
        dispatch(CartItems(guestConfig, cartData))
      }
    }

    const createOrder = (data, actions) => {
      return axios.post(process.env.REACT_APP_BACKEND_URL+`/orders/paypal/create/`, {}, config)
          .then(function(orderData) {
              return orderData.data.id;
          });
    }
    
    const onApprove = (data, actions) =>{
        dispatch(purchMusic(config, data, actions))
        history.push('/download')
    }

    const { activeMusicId, setActiveMusic } = useActiveMusic();

    const setActiveModal = (modal) => {
      dispatch({type: SET_MUSIC, data: {activeModal: modal}})
    }

    const hasMusic = getAddToCartItemSuccess && getAddToCartItemSuccess.music && getAddToCartItemSuccess && getAddToCartItemSuccess.music.length>0;

    return (
      <>
        <div className="item-body item-body2 cart-container">
          <h4>Cart</h4>

          {getAddToCartItemSuccess && getAddToCartItemSuccess.music.map((music, index) =>(
            <CartItem key={`m${music.id}`} className={index>0?'mt-3':''} music={music} isActive={activeMusicId===music.id} removeItemFormCart={removeItemFormCart} setActiveMusic={setActiveMusic}/>
          ))}

          {getAddToCartItemSuccess && getAddToCartItemSuccess.orders.map((music, index) =>(
            <CartItem key={`s${music.id}`} className={(index>0 || hasMusic)?'mt-3':''} music={music} isActive={activeMusicId===music.id} removeItemFormCart={removeItemFormCart} setActiveMusic={setActiveMusic}/>
          ))}
          
        </div>

        <div className="Price-Summary">
          <h4>Price Summary</h4>
          <div className="box text-end">
            <CartAmount data={getAddToCartItemSuccess}/>
            {!showPaypal && getAddToCartItemSuccess && getAddToCartItemSuccess.music.length+getAddToCartItemSuccess.orders.length>0 && <button onClick={PurchaseMusic} className="btn">Continue to purchase</button>}
            {(showPaypal && !accessToken) && 
            <>
              <p style={{textAlign: 'center', color: 'grey', marginBottom: 0, marginTop: 20}}>
                Registration is required to provide you an access to purchased midis.
                Please <a href="#" onClick={ee=>ee.preventDefault()||setActiveModal('registration')}>Register</a> or <a href="#" onClick={ee=>ee.preventDefault()||setActiveModal('login')}>Login</a> if you already have an account.
              </p>
              <div>
                <div style={{width: '50%', paddingRight: 6, float:'left'}}><button onClick={e=>setActiveModal('login')} className="btn">Login</button></div>
                <div style={{width: '50%', paddingLeft: 6, float:'right'}}><button onClick={e=>setActiveModal('registration')} className="btn">Register</button></div>
              </div>
              <p style={{textAlign: 'center', color: 'grey', marginBottom: 10, paddingTop: 10, clear: 'both'}}>OR</p>
              <div className={styles.socialHolder}><SocialLogin /></div>
            </>
            }
            {showPaypal && accessToken && getAddToCartItemSuccess && (getAddToCartItemSuccess.music.length>0||getAddToCartItemSuccess.orders.length>0) &&
            <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, components: "buttons" }}>
                <div style={{marginTop:15}} className={styles.paypalButtonHolder}>
                    {showPaypal && (
                        <PayPalButtons
                            style ={{"layout":"vertical"}}
                            disabled={false}
                            createOrder={(data, actions) => createOrder(data, actions)}
                            onApprove={(data, actions) => onApprove(data, actions)}
                    />

                    )}
                </div>
            </PayPalScriptProvider>
            }
          </div>
        </div>
      </>
    )
}

export default Cart
