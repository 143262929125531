import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import SongItem from '../../component/songItem/SongItem'
import { useActiveMusic } from '../../service/activeMusicService'
import {purchedMusicList} from '../../store/action/MusicAction'
import SongSearchItem from '../../component/songSearchItem/SongSearchItem'
import { useCart } from '../../service/cartService'
import { getAccessToken } from '../../utils'
import { useDownloadMidi } from '../../service/downloadMidiService'
import { Synthesia } from '../layout/Layout'


function DownloadMusic() {
    const dispatch = useDispatch()
    const { getPuchsedItemSuccess: tabs } = useSelector(state => state.music)

		const { activeMusicId, setActiveMusic } = useActiveMusic();
    const { addToCart } = useCart();

    const [kind, setKind] = useState('available');

    const { downloadFull, downloadSegment } = useDownloadMidi();

    const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + getAccessToken(),
			},
		};
    
    useEffect(() => {
			dispatch(purchedMusicList(config))
    }, [])

    useEffect(() => {
      if (tabs) {
        const { fresh, music, pending } = tabs;
        const kind = fresh.length && 'fresh' || music.length && 'available' || pending.length && 'pending';
        setKind(kind);
      }
    }, [tabs && tabs.fresh, tabs && tabs.music, tabs && tabs.pending])

    const isEmpty = tabs && !tabs.fresh.length && !tabs.music.length && !tabs.pending.length;

    return (
      <div className="Genre">
        <div className="Genre-inner">
          <div className="nav_body">
            <h1>Download Your Midi</h1>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            {tabs && [['fresh', 'New', 'fresh'], ['available', 'Available', 'music'], ['pending', 'Pending', 'pending']].map(([tabKey, tabName, propName]) => (
              tabs && tabs[propName].length>0 && 
              <li className="nav-item" role="presentation">
                  <button onClick={ee=>setKind(tabKey)} className={`nav-link${tabKey===kind?' active':''}`} type="button" role="tab" aria-controls="pills-home" aria-selected="true">{tabName}</button>
              </li>
            ))}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className={`tab-pane fade ${kind === 'fresh'?'show active':''}`} role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="row ">
                  <div className="col-lg-6">
                    <div className="item-body item-body2">
                      {tabs && tabs.fresh.map((item, index) =>( 
                        <SongItem key={index} music={item.midi||item} type={item.midi?'':'download'} isActive={(item.midi||item).id==activeMusicId} setActiveMusic={setActiveMusic} download={item.midi?downloadSegment:downloadFull} addToCart={addToCart} />
                      ))}
                    </div>
                  </div>
                  <Synthesia />
                </div>
              </div>
              <div className={`tab-pane fade ${kind === 'available'?'show active':''}`} role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="row ">
                  <div className="col-lg-6">
                    <div className="item-body item-body2">
                      {tabs && tabs.music.map((music, index) =>(
                        <SongItem key={index} music={music} type="download" isActive={music.id===activeMusicId} setActiveMusic={setActiveMusic} download={downloadFull} />
                      ))}
                    </div>
                  </div>
                  <Synthesia />
                </div>
              </div>
              <div className={`tab-pane fade ${kind === 'pending'?'show active':''}`} role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="row ">
                  <div className="col-lg-6">
                    <div className="item-body item-body2">
                      {tabs && tabs.pending.map((item, index) =>(
                        item.midi 
                          ? <SongItem key={index} music={item.midi} type={item.is_paid?'download':''} isActive={item.midi.id==activeMusicId} setActiveMusic={setActiveMusic} download={item.is_paid?downloadFull:downloadSegment} addToCart={addToCart} />
                          : <SongSearchItem key={index} music={item} status={item.is_paid?'ordered':'requested'} addToCart={addToCart} />
                      ))}
                    </div>
                  </div>
                  <Synthesia />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default DownloadMusic
