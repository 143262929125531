import React from 'react'

import { Link } from 'react-router-dom';


function MidiBanner({isAboutPage}) {
  return (
    <div className="mide-banner">
      <div className="row g-0">
        <div className="col-lg-7">
          <div className="item">
            <h1>Hi, Welcome to MidiStax</h1>
            <p>A library of piano midi files for music creatives </p>
            {isAboutPage
              ? <Link to="/">Discover Midi Files </Link>
              : <Link to="/about">Discover the benefits of midi </Link>
            }
          </div>
        </div>
        <div className="col-lg-5">
          <div className="item-img">
            <img src="/img/piano1.png" alt="img" className="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MidiBanner
